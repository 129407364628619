import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Sidebar.module.scss";
import logo from "../assets/logo.svg";
import SearchDrawer from "./SearchDrawer";
import NotiDrawer from "./NotiDrawer";
import Button from "app/components/button/button";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import LanguageSelector from "app/util/LanguageSelector";
import Header from "header/Header";

export default function Sidebar({
  isLoggedIn,
  children,
  containerRef,
}: {
  isLoggedIn: boolean;
  children: any;
  containerRef: any;
}) {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);

  const origin = window.location.origin;
  const pathname = window.location.pathname;

  const navLinks = [
    {
      name: <Trans>profile</Trans>,
      to: `/${userInfo?.id}/posts`,
      icon: origin + "/assets/icons/profile",
      auth: true,
      type: "page",
    },
    {
      name: "Discover",
      to: "/discover/",
      icon: origin + "/assets/icons/discover",
      auth: false,
      type: "page",
    },
    {
      name: "VIP Clubs",
      to: "/clubs/",
      icon: origin + "/assets/icons/club",
      auth: false,
      type: "page",
    },
    /* {
      name: <Trans>notifications</Trans>,
      to: "#",
      icon: origin + "/assets/icons/notification",
      auth: true,
      type: "notifications",
    }, */
  ];

  return (
    <>
      <Header />
      <div className={`${styles.sidebar} container`} style={notiDrawer || searchDrawer ? { overflow: "hidden"} : {}}>
        {!pathname.includes("/login") && (
          <div
            className={
              notiDrawer || searchDrawer
                ? `${styles.sidebarCollapse} ${styles.sidebarContent}`
                : styles.sidebarContent
            }
          >
            {/* Search */}
            <SearchDrawer show={searchDrawer} setShow={setSearchDrawer} />

            {/* Notifications */}
            <NotiDrawer show={notiDrawer} setShow={setNotiDrawer} />

            <div
              className={
                userInfo
                  ? styles.navbar
                  : `${styles.navbar} ${styles.navbar_hm}`
              }
            >
              {/* <div className={styles.logo_section}>
                <img src={logo} className={styles.logo} alt="logo" />
                <span>Arena</span>
              </div>
 */}
              <ul>
                {isLoggedIn && (
                  <li
                    className={location.pathname === "/" ? styles.active : ""}
                  >
                    <Link
                      to={"/"}
                      onClick={() => {
                        setSearchDrawer(false);
                        setNotiDrawer(false);
                      }}
                    >
                      <img
                        alt={"home"}
                        src={
                          location.pathname === "/"
                            ? `/assets/icons/home_active.svg`
                            : `/assets/icons/home.svg`
                        }
                      />
                      <span>Feed</span>
                    </Link>
                  </li>
                )}

                {navLinks.map((item: any, index: Number) => {
                  return (
                    <li
                      className={
                        location.pathname === item.to ? styles.active : ""
                      }
                      key={`item-${index}`}
                      hidden={item.auth ? !Boolean(userInfo) : false}
                    >
                      <Link
                        to={item.to}
                        onClick={() => {
                          if (item.type === "search") {
                            notiDrawer && setNotiDrawer(false);
                            setSearchDrawer(!searchDrawer);
                          } else if (item.type === "notifications") {
                            searchDrawer && setSearchDrawer(false);
                            setNotiDrawer(!notiDrawer);
                          } else {
                            setSearchDrawer(false);
                            setNotiDrawer(false);
                          }
                        }}
                      >
                        <img
                          alt={item.name}
                          src={
                            location.pathname === item.to
                              ? `${item.icon}_active.svg`
                              : `${item.icon}.svg`
                          }
                        />
                        <span>{item.name}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <div className={styles.footer}>
                {/* <LanguageSelector /> */}
                {/* {isLoggedIn ? (
                  <Button
                    outline={false}
                    onClick={() => {
                      logout();
                      window.location.reload();
                    }}
                  >
                    <img alt="logout" src={`/assets/icons/logout.svg`} />
                    <span>Logout</span>
                  </Button>
                ) : (
                  <Button onClick={() => setShowLoginModal(true)}>Login</Button>
                )} */}
                {/* {!isLoggedIn && <Button onClick={() => setShowLoginModal(true)}>Login</Button>} */}
              </div>
            </div>
          </div>
        )}
        <div className={styles.bodyContent} id="bodyContent" ref={containerRef}>
          {children}
          <div className={styles.walletSidebar} id="sidebarContent">
            {/* My Wallet */}
          </div>
        </div>
      </div>
    </>
  );
}
