import React, { useEffect, useState } from 'react';
import Modal from '../Modal';

import Loading from '../../assets/images/loading.svg';
import styles from './notification.module.scss';
import InProgressError from './InProgressError';

const InProgress = ({ setTheme, redirectURL }: { setTheme: any, redirectURL: string | undefined; }) => {
  const [isError, showError] = useState(false);
  const delay = 4;

  function onClose() {
    console.log('onclose')
    setTheme({
      type: undefined,
      subType: undefined,
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => showError(true), delay * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    isError ? <InProgressError setTheme={setTheme} redirectURL={redirectURL}  /> :
    <Modal isOpen={true} title='' onClose={() => onClose()} zIndex={4} center>
      <div className={styles.notification_content}>
        <div className={styles.image_content}>
          <div className={styles.lds_ring}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p className={styles.title}>Transaction in Progress</p>
          <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            Please wait while your request is being processed
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InProgress;
