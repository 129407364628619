import { handlePostComment } from "app/api/actions.api";

export const handleCommentCount = async ({
  cookie,
  userInfo,
  itemId,
  setItems,
  newComment,
}) => {
  setItems((prevItems) =>
    prevItems.map((item) => {
      return item.id === itemId
        ? {
            ...item,
            comments: Number(item.comments) + 1,
          }
        : item;
    })
  );
  return await handlePostComment({
    cookie,
    userInfo,
    post_id: itemId,
    comment: newComment,
  });
  return;
};
