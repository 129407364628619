import { useState } from "react";
import axios from "axios";
import { useHook } from "app/hooks/common";

/************************/
// Handle Follow/Unfollow
/************************/
export async function handleFollow({ cookie, userInfo, id }) {
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/follow`,
      method: "post",
      data: {
        cookie,
        userInfo,
        follower_id: id,
      },
    });
    // return success message
    if (res.data) {
      return {
        success: true,
        message: res.data,
      };
    } else {
      return {
        success: false,
        message: res,
      };
    }
  } catch (error) {
    // return error message
    console.error(error);
    return error;
  }
}

export async function handlePostLike({ cookie, userInfo, post_id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/action/like`,
    method: "post",
    data: {
      cookie,
      userInfo,
      post_id,
    },
  });

  return result.data;
}

export async function handlePostComment({
  cookie,
  userInfo,
  post_id,
  comment,
}) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/action/comment`,
    method: "post",
    data: {
      cookie,
      userInfo,
      post_id,
      comment,
    },
  });

  return result.data;
}
