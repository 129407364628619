import { useEffect, useRef, useState } from "react";
import styles from "./createContent.module.scss";
import { useHook } from "app/hooks/common";
import { DirectusImage } from "app/core/common";
import axios from "axios";
import { useFeed } from "app/context/feedContext";
import { t } from "i18next";
import Loader from "../Loader";
import { set } from "numeral";

const img_icon =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='%23c5c6ca'%3E%3Cpath fill='%23c5c6ca' d='M2,2h26v17h2V1c0-0.552-0.447-1-1-1H1C0.447,0,0,0.448,0,1v28c0,0.552,0.447,1,1,1h18v-2H2V2z'%3E%3C/path%3E %3Ccircle fill='%23c5c6ca' cx='21' cy='9' r='3'%3E%3C/circle%3E %3Cpolygon points='28,22 26,22 26,26 22,26 22,28 26,28 26,32 28,32 28,28 32,28 32,26 28,26 '%3E%3C/polygon%3E %3Cpath fill='%23c5c6ca' d='M9.8,10.02c-0.313,0.064-0.577,0.274-0.71,0.566l-5,11c-0.141,0.309-0.114,0.669,0.069,0.955 C4.344,22.827,4.66,23,5,23h17c0.412,0,0.782-0.253,0.932-0.638c0.149-0.384,0.048-0.821-0.256-1.1l-12-11 C10.439,10.047,10.115,9.96,9.8,10.02z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";

export const CreateContent = ({ feedRef, updateFeedRef }) => {
  const { userInfo, cookie, isCreator } = useHook();
  const textareaRef = useRef(null);
  const { feed, setFeed } = useFeed();
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [showPostForm, setShowPostForm] = useState(false);
  const [postVisibility, setPostVisibility] = useState("Public");
  const [postContent, setPostContent] = useState("");
  const [postFileType, setPostFileType] = useState("");
  const [postMedia, setPostMedia] = useState(null);
  const postTypeOptions = [
    {
      text: "Public",
      value: "Public",
    },
    {
      text: "Private",
      value: "Private",
    },
  ];

  // Reset Form
  const resetForm = () => {
    setShowPostForm(!showPostForm);
    setImageURL(null);
    setPostContent("");
    setPostFileType("");
    setPostMedia(null);
  };

  // Handle File Upload
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    const isValidFileType = (fileType) => {
      // Allowed file types
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "video/mp4",
        "video/quicktime",
      ];
      return allowedTypes.includes(fileType);
    };

    if (file) {
      if (!isValidFileType(file.type)) {
        // Display an error message in an alert
        alert("Invalid file type. Please select an image or video.");
        setPostMedia(null);
        return;
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageURL(reader.result);
        };
        reader.readAsDataURL(file);
        const fileType = file.type.split("/")[0];
        setPostFileType(fileType);
        setPostMedia(file);
      }
    }
  };

  // Handle post Creation
  const handlePostCreation = async () => {
    // Set Loading
    setLoading(true);

    // Create FormData
    const formData = new FormData();
    formData.append("image", postMedia as Blob);

    // Append additional fields to formData
    formData.append("user_id", userInfo.id);
    formData.append("profile_id", userInfo.profile_id);
    formData.append("cookie", cookie);
    formData.append("post_content", postContent);
    formData.append("post_visibility", postVisibility);
    formData.append("post_FileType", postFileType);

    // Post Data
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_MIDDLEWARE}user/action/post`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((response) => {
        const { data } = response.data;

        // Push to Feed
        const feedItemObject = {
          ...data,
          liked: false,
          image: {
            id: data.media,
          },
          artist: {
            first_name: userInfo.first_name,
            username: `${userInfo.username}`,
            avatar: `${userInfo.avatar}`,
            id: `${userInfo.id}`,
          },
          access: true,
          timestamp: new Date().toISOString(),
        };

        // add item to feed
        updateFeedRef([feedItemObject, ...feedRef]);

        // update main feed
        setFeed([feedItemObject, ...feed]);

        // clear data and close form
        resetForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Auto Grow TextArea
  const autoGrow = (event: any) => {
    event.target.style.height = "24px";
    event.target.style.height = event.target.scrollHeight + 24 + "px";
  };

  // Grow Textarea
  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.addEventListener("input", autoGrow);
    }
    return () => {
      if (textarea) {
        textarea.removeEventListener("input", autoGrow);
      }
    };
  }, []);

  return (
    userInfo && (
      <>
        <div className={styles.container}>
          <button
            className={`${styles.createButton} btn_full`}
            onClick={() => setShowPostForm(!showPostForm)}
            style={
              !showPostForm
                ? {
                    opacity: "1",
                    transform: "translateY(0)",
                    transition: "ease-in-out 400ms",
                  }
                : {
                    opacity: "0",
                    transform: "translateY(100%)",
                    transition: "ease-in-out 400ms",
                  }
            }
          >
            {t("createContent.post")}
          </button>

          <div
            className={styles.contentArea}
            style={
              showPostForm
                ? {
                    opacity: "1",
                    marginTop: "0",
                    transform: "translateY(0)",
                    transition: "ease-in-out 800ms",
                  }
                : {
                    opacity: "0",
                    marginTop: "-50%",
                    transform: "translateY(100%)",
                    transition: "ease-in-out 400ms",
                  }
            }
          >
            <button
              hidden={loading}
              className={`${styles.button} ${styles.close}`}
              onClick={() => {
                resetForm();
              }}
            >
              X
            </button>
            <div className={styles.header}>
              <div className={styles.profile}>
                <img
                  src={
                    userInfo?.avatar
                      ? DirectusImage(userInfo?.avatar)
                      : "/blank.svg"
                  }
                  alt=""
                />
                <div>
                  <p>
                    <b>{userInfo?.first_name}</b>
                  </p>
                  <p>0 Seconds Ago</p>
                </div>
              </div>
            </div>

            {/* Post Type Selection */}
            {/* Show if user is Creator */}
            {isCreator && (
              <form>
                <div className={styles.radio}>
                  {postTypeOptions.map((option) => {
                    return (
                      <div className={styles.radioButton}>
                        <input
                          type="radio"
                          value={option.value}
                          checked={postVisibility == option.value}
                          id={`post-visibility-${option.value}`}
                        />
                        <label
                          className={styles.radioLabel}
                          htmlFor={`post-visibility-${option.value}`}
                          onClick={() => setPostVisibility(option.value)}
                        >
                          {option.text}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </form>
            )}

            {/* Text Area */}
            <textarea
              value={postContent}
              placeholder={t("createContent.placeholder")}
              onChange={(e) => setPostContent(e.target.value)}
            />

            {/* File Upload */}
            <div className={styles.fileUpload}>
              {imageURL && postFileType === "image" && (
                <img
                  src={imageURL}
                  alt="Preview"
                  className={styles.imagePreview}
                />
              )}
              {imageURL && postFileType === "video" && (
                <video
                  src={imageURL}
                  controls
                  loop
                  autoPlay={false}
                  className={styles.imagePreview}
                />
              )}
            </div>

            <div className={styles.actions}>
              <label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept="image/*, video/*"
                />
                <img src={img_icon} alt="Upload" />
              </label>
              <button
                disabled={
                  postContent.length < 10 || postMedia === null || loading
                }
                className={`${styles.createButton} ${styles.createButtonSend} btn_default`}
                onClick={() => handlePostCreation()}
              >
                {t("createContent.post")}
              </button>
            </div>

            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  backgroundColor: "rgb(23 25 27 / 48%)",
                }}
              >
                <Loader />
              </div>
            )}
          </div>
          <hr />
        </div>
      </>
    )
  );
};
