import { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Profile.module.scss";

import Button from "app/components/button/button";
import link_icon from "../../../assets/icons/link.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as api from "app/api/profile.api";
import ProfileNfts from "./Nfts";
import { DirectusImage, ProfileData, isCreator } from "app/core/common";
import ProfileCollections from "./Collections";
import CreatorCourses from "./Courses";
import ProfileFeed from "./Feed";
import ProfileGallery from "./Gallery";
import { useFollow } from "app/state/FollowState";
import { useHook } from "app/hooks/common";
import { Trans } from "react-i18next";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import LoginModal from "app/components/loginModal/LoginModal";
import { useLogin } from "app/context/loginProvider";
import { ProfileTabs } from "./ProfileTabs";
import ProfileEvents from "./Events/Events";

export default function Profile({ containerRef }) {
  const location = useLocation();
  const history = useHistory();

  const userId = location.pathname.split("/")[1];
  const tabName = location.pathname.split("/")[2];

  const { logout } = useWallet();
  const { userInfo } = useHook();
  const isUserProfile = userInfo?.id === userId;

  const [profileData, setProfileData] = useState<ProfileData>(null);

  const profileImage = profileData?.avatar?.id;

  const [feed, setFeed] = useState([]);
  const [feedMeta, setFeedMeta] = useState<any>();
  const [page, setPage] = useState(1);
  const { isFollowing, isFollowingLoading, toggleFollow } = useFollow({
    id: userId,
  });
  const prevUsernameRef = useRef<string | null>(null);
  const access_courses = profileData?.access_courses ? true : false;
  const { toggleLoginModal } = useLogin();

  /**********************/
  // Check if Following
  /**********************/
  /*  useEffect(() => {
    const following = followingList.find(
      (obj) => obj.follower_id.id === username
    );
    setIsFollowing(following);
    setIsFollowingLoading(false);
  }, [followingList]); */

  /**********************/
  // Clear Feed/Profile data on profile change
  /**********************/
  useEffect(() => {
    if (
      prevUsernameRef.current !== null &&
      prevUsernameRef.current !== userId
    ) {
      setFeed([]);
      setFeedMeta({});
      setPage(1);
      setProfileData(null);
    }
    prevUsernameRef.current = userId;
  }, [userId]);

  /**********************/
  // Get Profile/Owner data
  /**********************/
  useEffect(() => {
    api.GetProfile({ id: userId }).then((user) => {
      setProfileData(user[0]);
    });
  }, [userId]);

  /**********************/
  // Check if page has tab route if not redirect to posts
  /**********************/
  useEffect(() => {
    if (!tabName) {
      history.push(`/${userId}/posts`);
    }
  }, [tabName]);

  /**********************/
  // Get Profile Feed
  /**********************/
  /*   useEffect(() => {
    api.GetProfileFeed({ id: userId, page: page }).then((data) => {
      setFeed(data.feed);
      setFeedMeta(data.meta);
    });
  }, []); */

  /**********************/
  // Handle Tab Change
  /**********************/
  const handleTab = useCallback(
    (tabName: any) => {
      history.push(`/${userId}/${tabName}`);
    },
    [history, userId]
  );

  return (
    <div className={styles.profile}>
      {profileData ? (
        <>
          <div
            className={styles.header}
            style={{
              backgroundImage: `url(${DirectusImage(
                profileData?.background?.id
              )})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <div className={styles.profile_header}>
            <div className={styles.avatar}>
              <img
                src={profileImage ? DirectusImage(profileImage) : "/blank.svg"}
              />
            </div>
            <div className={styles.content}>
              <div className={styles.creator}>
                <div className={styles.details}>
                  <div>
                    <div className={styles.name}>{profileData?.first_name}</div>
                    {profileData?.creator_type != "None" && (
                      <div className={styles.type}>
                        <span>{profileData?.creator_type}</span>
                      </div>
                    )}
                    {/* <div className={styles.socials}>
                    {profileData?.socials?.map((obj, index) => {
                      return (
                        <a
                          href={obj.url}
                          target="_blank"
                          className={styles.link}
                          key={index}
                        >
                          <img className={styles.link_icon} src={`/assets/icons/${obj.network}.svg`} />
                          <span>{obj.network}</span>
                        </a>
                      );
                    })}
                  </div> */}
                  </div>

                  <div className={styles.actions}>
                    {isUserProfile ? (
                      <>
                        {/* <Button
                          className="login_button"
                          onClick={() => {
                            logout();
                            window.location.reload();
                          }}
                        >
                          <span>Logout</span>
                        </Button> */}
                      </>
                    ) : userInfo ? (
                      <Button
                        disabled={isFollowingLoading}
                        onClick={() => !isFollowingLoading && toggleFollow()}
                      >
                        {isCreator(profileData?.role) && isFollowing ? (
                          <Trans>following</Trans>
                        ) : (
                          <Trans>follow</Trans>
                        )}
                      </Button>
                    ) : (
                      <Button onClick={() => toggleLoginModal()}>Login</Button>
                    )}
                  </div>
                </div>
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={{
                    __html: profileData?.description,
                  }}
                />
              </div>
            </div>
          </div>

          <ProfileTabs
            user={profileData}
            tabName={tabName}
            handleTab={(name: string) => handleTab(name)}
          />

          <div className="content">
            {(tabName == "posts" || tabName == "") && (
              <ProfileFeed username={userId} containerRef={containerRef} />
            )}
            {isCreator(profileData?.role) && tabName == "collections" && (
              <ProfileCollections username={profileData?.username} />
            )}
            {isCreator(profileData?.role) && tabName == "courses" && (
              <CreatorCourses />
            )}
            {(!isCreator(profileData?.role) || isUserProfile) &&
              tabName == "nfts" && <ProfileNfts containerRef={containerRef} />}
            {isCreator(profileData?.role) && tabName == "gallery" && (
              <ProfileGallery isFollowing={isFollowing} creator={profileData} />
            )}
            {isCreator(profileData?.role) && tabName == "events" && (
              <ProfileEvents creator={profileData} />
            )}
          </div>
        </>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
}
