import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Link, useLocation, useParams } from "react-router-dom";

import * as api from "app/api/collections.api";
import { CurrencyRateType, IPFSImage, Redeemable } from "app/core/common";
import NFTInfo from "./NFTInfo";
import { Trans } from "react-i18next";
import NFTDesc from "./NFTDesc";
import Redeemables from "./Redeemables";
import {
  LaunchPadNFTQueryInfo,
  getRedeemablesForNft,
  queryLaunchpadNFTInfo,
} from "app/api/common.api";
import { useHook } from "app/hooks/common";

export default function NftDetail() {
  const params = useParams<any>();
  const { address, cookie } = useHook();

  const [redeemables, setRedeemables] = useState<Redeemable[]>([]);
  const [showQrCodeModal, setShowQrCodeModal] = useState(
    Array(redeemables.length).fill(false)
  );

  const [nftAddress] = useState(params && params.address);
  const [nftTokenId] = useState(params && params.id);
  const [attributesCount, setAttributesCount] = useState<number[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [info, setInfo] = useState<LaunchPadNFTQueryInfo>();
  const [owner, setOwner] = useState<boolean>(false);

  // GET NFT DETAIL
  useEffect(() => {
    queryLaunchpadNFTInfo(nftAddress, nftTokenId)?.then(
      (result: LaunchPadNFTQueryInfo) => {
        if (result) {
          //setAttributesCount(result.metadata.attributes.map((_) => 0));
          setTotalCount(0);

          setInfo({
            ...result,
          });
          setOwner(result.info.owner === address);
        }
      }
    );
  }, [nftAddress, nftTokenId, address]);

  useEffect(() => {
    try {
      const fetchRedeemables = async () => {
        let redeemablesResult = await getRedeemablesForNft({
          contract: nftAddress,
          tokenId: nftTokenId,
          address: address,
          cookie: cookie,
        });
        //console.log("redeemablesResult", redeemablesResult);
        setRedeemables(redeemablesResult);
      };
      if (cookie && address && owner) {
        fetchRedeemables().catch((err) => console.log(err));
      }
    } catch (error) {
      console.log(error);
    }
  }, [info, nftTokenId, nftAddress, address, owner]);

  return (
    <div className={styles.nft_detail}>
      {/*
        <Link className={styles.back} to={`/${userInfo.id}`}>
          <img src="/back.svg" alt="back_icon" style={{ marginRight: "10px" }} />{" "}
          My NFTs
        </Link>
      */}
      {info ? (
        <>
          <div className={styles.launchViewHeader}>
            <div className={styles.launchViewLeft}>
              <div className={styles.nft_image}>
                <img src={info && IPFSImage(info?.metadata?.image, true)} />
              </div>
            </div>
            <div className={styles.launchViewRight}>
              <NFTInfo info={info} hasRedeemables={redeemables.length > 0} owner={owner} />
            </div>
          </div>

          <NFTDesc info={info} />
          {redeemables.length > 0 && owner && <Redeemables redeemables={redeemables} />}
        </>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
}
