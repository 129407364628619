import TimeAgo from "app/helpers/timeago";
import styles from "./comments.module.scss";
import { useEffect, useState } from "react";
import * as api from "app/api/user.api";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { useUser } from "app/context/userInfo";
import { DirectusImage } from "../../core/common";
import { Link } from "react-router-dom";
import { truncate } from "app/helpers/helper";
import ReactPlayer from "react-player";

export default function Comments({
  post,
  comments,
  commentLoading,
}: {
  post: any;
  comments: any;
  commentLoading: Boolean;
}) {
  return (
    <>
      {/* loop comments */}
      <div className={styles.comments}>
        {/* Post Detail */}
        <div className={styles.post}>
          <div className={styles.post_artist}>
            <Link to={`/${post.artist.id}`}>
              <img src={DirectusImage(post.artist.avatar)} />
            </Link>
          </div>
          <div className={styles.post_detail}>
            <div>
              <p>
                <b>{post.artist.first_name}</b> -{" "}
                <span className={styles.post_date}>
                  {TimeAgo(post.date_created)}
                </span>
              </p>
              {/* <p
                className={styles.content}
                dangerouslySetInnerHTML={{
                  __html: truncate(post.content, 550),
                }}
              /> */}
              <div className={styles.content}>
                <p>{truncate(post.content, post.content.length)}</p>
                <div className={styles.content_media}>
                  {post.type == "video" ? (
                    <ReactPlayer
                      url={DirectusImage(post.image.id)}
                      controls={true}
                      playsinline
                      //playing
                      muted // Add the muted attribute
                      autoplay
                      //loop
                      pip={false}
                      width="100%"
                      height="100%"
                    />
                  ) : (
                    <>
                      <img
                        src={DirectusImage(post.image.id)}
                        alt={`${post.id}`}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {commentLoading && (
          <div className={styles.loader}>
            <div className="loader" />
          </div>
        )}

        <p>Comments</p>
        {comments?.map((comment, index) => {
          const profileImage = comment?.user_id?.avatar?.id;
          return (
            <div key={index}>
              <div className={styles.comment}>
                <div className={styles.comment_user}>
                  <Link to={`/${comment.user_id?.id}`}>
                    <img
                      src={
                        profileImage
                          ? DirectusImage(comment?.user_id?.avatar?.id)
                          : "/blank.svg"
                      }
                    />
                  </Link>
                </div>
                <div className={styles.comment_detail}>
                  <div>
                    <p>
                      <b>{comment?.user_id.first_name}</b> -{" "}
                      <span className={styles.date}>
                        {TimeAgo(comment?.date_created)}
                      </span>
                    </p>
                    <p className={styles.content}>{comment?.Text}</p>
                    <div className={styles.actions}>
                      {/* <div className={styles.action}> Like </div> */}
                      {/* Add Replies at a future date */}
                      {/* <div className={styles.action}> Reply </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
