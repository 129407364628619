import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Header from "./Header";
import Carousel from "app/components/carousel/Carousel";
import { Link } from "react-router-dom";

export default function CreatorCourse() {
  return (
    <div className={styles.course}>
      <Header
        creator={true}
        contentType="feed"
        content={
          <div>
            <div className="breadcrumb flat">
              <Link to="/82fa9421-13ef-4c29-8a4a-160916d5a8a0/" className="active">
                Trip Courses
              </Link>
              <Link to="/courses/demo" className="active">
                Course Type 1
              </Link>
              <Link to="/courses/demo/fitness-demo">
                fitness-demo
              </Link>
            </div>
            <div className={styles.section} style={{ marginTop: "20px" }}>
              <h3 className={styles.title}>About this Course</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
                pulvinar lacus sem. Vivamus porttitor eu tellus dapibus ornare.
                Praesent odio nunc, egestas non ligula et, lobortis maximus mi.
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Fusce nec leo feugiat,
                dapibus odio eu, pharetra magna. Mauris elementum est eget ipsum
                cursus, quis sollicitudin neque pulvinar. Duis a mi non eros
                ornare sagittis non vitae est. Quisque nec ante diam. Sed
                gravida nisi quis purus malesuada lacinia. Sed ac semper libero.
                Aenean sit amet quam sed purus egestas elementum volutpat in
                augue. Vestibulum ante ipsum primis in faucibus orci luctus et
                ultrices posuere cubilia curae; Aliquam in varius urna, nec
                congue tortor. Proin luctus volutpat lacus et pulvinar.
              </p>
            </div>

            <div className={styles.section} style={{ marginTop: "100px" }}>
              <h5 className={styles.title}>What you'll learn</h5>
              <p>
                <ul>
                  <li>1. Aliquam in varius urna, nec congue tortor.</li>
                  <li>2. Aliquam in varius urna, nec congue tortor.</li>
                  <li>3. Aliquam in varius urna, nec congue tortor.</li>
                  <li>4. Aliquam in varius urna, nec congue tortor.</li>
                </ul>
              </p>
            </div>
          </div>
        }
      />
    </div>
  );
}
