// src/LanguageSelector.tsx
import React, { useState, useEffect } from "react";

const languages = [
  { value: "en", label: "English", flag: <img src="/en-us.svg" alt="US Flag" /> },
  { value: "es", label: "Español", flag: <img src="/es-us.svg" alt="Spain Flag" /> },
];

function LanguageSelector() {
  const pattern = /(es)/;
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    localStorage.getItem("i18nextLng") ||
      (languages.find(
        (lang) => lang.value === navigator.language
      )?.value || "en-US")
  );

  const [showFullText, setShowFullText] = useState(false);

  useEffect(() => {
    localStorage.setItem("i18nextLng", selectedLanguage);
  }, [selectedLanguage]);

  const handleToggleText = () => {
    setShowFullText((prevShowFullText) => !prevShowFullText);
  };

  const handleSelectLanguage = (language: string) => {
    setSelectedLanguage(language);
    setShowFullText(false);

    window.location.reload();
  };

  return (
    <div className="language-selector">
      <button onClick={handleToggleText}>
        {languages.find((lang) => selectedLanguage.includes(lang.value))?.flag}{" "}
        {pattern.test(selectedLanguage) ? "ES" : "EN"}
      </button>
      {showFullText && (
        <div className="dropdown-options">
          {languages.map((lang) => (
            <div
              key={lang.value}
              onClick={() => handleSelectLanguage(lang.value)}
            >
              {lang.flag} {lang.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
