import { useEffect, useRef, useState } from "react";
import styles from "./collections.module.scss";

import { Link, useLocation } from "react-router-dom";
import { DirectusImage, CourseUrl, CreatorCollections } from "app/core/common";
import * as api from "app/api/collections.api";
import Loader from "app/components/Loader";

interface props {
  username: string;
}

export default function ProfileCollections({ username }: props) {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.getCreatorCollections({ username: username }).then((data) => {
      setCollections(data);
      setLoading(false);
    });
  }, []);

  return (
    <div className={styles.courses}>
      {loading ? (
        <Loader />
      ) : (
        collections?.map((item, index) => {
          return (
            <Link
              to={`/collection/${CourseUrl(item?.project_slug)}`}
              key={index}
              className={styles.card}
            >
              <div className={styles.courseImage}>
                <img src={DirectusImage(item?.banner?.id)} />
              </div>
              <p className={styles.title}>{item?.project_name}</p>
              <p className={styles.description}>
                {item?.launchpad_type?.fan_collection?.description}
              </p>
            </Link>
          );
        })
      )}
    </div>
  );
}
