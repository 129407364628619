import styles from "./index.module.scss";
import Button from "../button/button";
import reloadIcon from "../../../assets/icons/reload.svg";
import TimeAgo from "../../helpers/timeago";
import { Trans } from "react-i18next";

export default function FeedHeader({
  feedLoading,
  fetchLatestFeed,
  lastUpdated,
}) {
  return (
    <div className={styles.feed_header}>
      <div className={styles.left}>
        <h4>Feed</h4>
        <p><Trans>feedUpdateText</Trans> {TimeAgo(lastUpdated)}</p>
      </div>
      {/* <div className={styles.action}>
        <Button
          className={styles.button_outline}
          outline={true}
          onClick={() => fetchLatestFeed()}
          disabled={Boolean(feedLoading)}
        >
          <span>
            <img
              className={feedLoading ? styles.loading : styles.icon}
              src={reloadIcon}
            />{" "}
            Refresh
          </span>
        </Button>
      </div> */}
    </div>
  );
}
