import { useContext } from 'react';
import axios from 'axios';
import { MINTER_ENDPOINT } from 'app/core/common';
import { NotificationContext } from 'app/context/notification';

type TxProps = {
  collection_addr?: string;
  address?: string;
};

// TODO: handle on backend
const COLLECTION_TO_MINTER = {
  "juno18x320xlm3428dgcns0zrjhwgk9t739hskeddvxzpy7nlhys6xxtq93nldp": "juno17vf3tu34x4ypum402lk8sp7d7ymvnz8sfcd7hwnwrwrumad4h7psdr764f",
  "juno19ac28lz2t4pv9lkputwjzkkf9d3ejqj3k6mzawedg8a3lcx2nakqvhx25w": "juno15zhpjr9fu9zjgkamlqanrmn6wpp8j77w5cz0maz3z25u43g2esgqwgusrc",
  "juno152534qjq2ajr3whzk8zj6ckang9n3czjrjm9lcf9g997d2sxm36s5sqcdw": "juno159h2s9xgnkv6uxcf0g4qujgq04mqk8vj6m93u72v3uau5cw97e2qlp7f2z",
  "juno1gacwk25kq99593zwjnj9lpjzk89j5kuzznf4fhrxwl38hnsexzjqmqkrwv": "juno1gacwk25kq99593zwjnj9lpjzk89j5kuzznf4fhrxwl38hnsexzjqmqkrwv", // Andrew Collection
  "juno1zsz9kl3rdhd6j6d64cmwhd5ezyk8l0t4ls3e50k52r2w4x9fz2js9qtsgx": "juno1lj9m7vdgsp5xxn7djmqgauj4hlee3tk0r54f6lea8ucsan03ehhq89t4zz", // Demo Collection
  "juno1lctyx6uvlqmafldcjahzjsqfncqgu3szysawcmnhnyn2v54v0xqsfts5t9": "juno1lctyx6uvlqmafldcjahzjsqfncqgu3szysawcmnhnyn2v54v0xqsfts5t9", // Xpecta Demo Collection
  "juno1rrsm8gj79xsesrpp4g5jtulp8zdjkuvxx88vv9rsy5qf2nuj6mash0m2fc": "juno1rrsm8gj79xsesrpp4g5jtulp8zdjkuvxx88vv9rsy5qf2nuj6mash0m2fc", // Xpecta Live Collection
  "juno18rxwd3xctwqx2g5sm9ykxtz4vkfpxd9fl6eu26pjhg2als7c6mqqvclzqr": "juno18rxwd3xctwqx2g5sm9ykxtz4vkfpxd9fl6eu26pjhg2als7c6mqqvclzqr", // Respira Live Collection
  "juno1j6elxhjyg24a7mf3ymw2w4asxu0guekexy2dw4604a3uyq3qycqs6vtkda": "juno1j6elxhjyg24a7mf3ymw2w4asxu0guekexy2dw4604a3uyq3qycqs6vtkda", // Star Festival
  "juno1m2g2humhwchcef9elhng3shstx885mg9z642g8txu8k8ck7d4wcspt2qgf": "juno1m2g2humhwchcef9elhng3shstx885mg9z642g8txu8k8ck7d4wcspt2qgf", // Bienbebida
  "juno10tgky483fmwk7nyytacv5xjfq8pyv28vd4pm34wukxepqvpgan2sehe07k": "juno10tgky483fmwk7nyytacv5xjfq8pyv28vd4pm34wukxepqvpgan2sehe07k", // Bienbebida
};

export default (props?: TxProps) => {
  const notificationContext = useContext(NotificationContext);

  const notificationEvent = ({
    subType,
    txErrorMessage,
    redirectURL,
    txHash,
  }: {
    subType: number;
    txErrorMessage?: string;
    redirectURL?: string;
    txHash?: string;
  }) => {
    notificationContext.setTheme({
      type: 0,
      subType: subType,
      ...(txErrorMessage && { txErrorMessage: txErrorMessage }),
      ...(redirectURL && { redirectURL: redirectURL }),
      ...(txHash && { txHash: txHash }),
    });
  };

  const MintNft = async ({
    collection_addr,
    address,
    redirectTo,
    mint_type,
    type_name,
  }: {
    collection_addr: string;
    address: string;
    redirectTo: string;
    mint_type: string;
    type_name: string;
  }) => {
    //console.log("MintNft", collection_addr, address);
    const minter = COLLECTION_TO_MINTER[collection_addr];
  
    try {
      if (!address || !collection_addr || !minter) {
        // Notification Error
        notificationEvent({ subType: 3 });
        return;
      } else {
        // Send Transaction
        // Notification Progress
        notificationEvent({ subType: 1 });
        const url = `${MINTER_ENDPOINT}minter/mint`;
        // const url = `${MINTER_ENDPOINT}/free_claim`;
        // console.log("Mint collection addr : ", minter);
        await axios({
          url,
          method: 'post',
          data: {
            type: "free_claim",
            query: {
              collection_addr: minter,
              recipient: address,
              type_name: type_name,
            },
          },
        })
          .then((res: any) => {
            if (res.status === 200) {
              notificationEvent({
                subType: 2,
                txHash: res.data.tx_hash,
                redirectURL: redirectTo,
              });
              return;
            } else {
              notificationEvent({ subType: 3, txErrorMessage: res.message });
              return;
            }
          })
          .catch(err => {
            //return error
            notificationEvent({
              subType: 3,
              txErrorMessage: err.response.data,
            });
            return;
          });
      }
    } catch (error) { }
  };

  return {
    MintNft,
  };
};
