import Countdown, { zeroPad } from "react-countdown";
import moment from "moment-timezone";
import { LaunchPadNFT } from "app/core/common";

const CountdownComp = ({
  launchpadNFT,
  index,
}: {
  launchpadNFT: LaunchPadNFT;
  index: number;
}) => {
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds }: any) => {
    return (
      <>
        <p>
          {days}d<span> : </span>
          {zeroPad(hours)}h<span> : </span>
          {zeroPad(minutes)}m<span> : </span>
          {zeroPad(seconds)}s
        </p>
      </>
    );
  };

  const currentTime = moment.tz("America/Los_Angeles").valueOf();
  const genTime = (time: string) =>
    moment(time).add(moment(time).utcOffset(), "minutes").valueOf();

  const whitelistStartTime = genTime(
    moment
      .tz("America/Los_Angeles")
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.startDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.startTime
        }`
      )
  );
  const publicMintStartTime = genTime(
    moment
      .tz("America/Los_Angeles")
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.publicDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.publicTime
        }`
      )
  );
  const publicMintEndTime = genTime(
    moment
      .tz("America/Los_Angeles")
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.endDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.endTime
        }`
      )
  );

  const beforeWhitelistMint = currentTime < whitelistStartTime;
  const isWhitelistMint =
    whitelistStartTime <= currentTime && currentTime < publicMintStartTime;
  const isPublicMint =
    publicMintStartTime <= currentTime && currentTime < publicMintEndTime;

  return (
    <>
      <div className="header">
        <div className="time">
          {/* {(beforeWhitelistMint || isWhitelistMint || isPublicMint) && ( */}
          <Countdown
            date={
              beforeWhitelistMint
                ? whitelistStartTime
                : isWhitelistMint
                ? publicMintStartTime
                : isPublicMint
                ? publicMintEndTime
                : new Date()
            }
            renderer={renderer}
          />
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default CountdownComp;
