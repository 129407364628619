import classNames from 'classnames'
import styles from './LoginWithGoogle.module.scss'
const  LoginWithFB = (props) => {
    return (<div {...props} className={classNames(styles.googleBtn, props?.className)}>
        <div className={styles.googleIconWrapper}>
            {/*<img className={styles.googleIcon} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB8klEQVR4nO2azUsCURDA35+2nTpFX4c6FIaQQYcI8tolOnQwOlWHIogMijoEIVRGlHjYDpuRElpKdSoWEz8y1F2dmEWldK1VcX1vez8YT8s6v53Z3feWIcKgBzCmnH4QJRlUtQS0o6olLVeH06/ljkHwZ3ElAAoDArUoSlHLXRPBSrAo8V1mcs4P5PpGBtYRJRkIC/fEX2BHEbAIBCwCAYtAwCIQsAgELALpdgLFUgniiRzEntPwJn9CLqeyJZLOFGB16x6G7efV9ZJQDvusD7b3HyCbVegWiT6lYGDCWycg1MTx6Qu9IslU3pCEQLuIa+3OkIRAswj2fO/oCfsioiT/mni/zQu2mSttw4ThE1/pFHEfRnUF+sbP4Db03ta5TRXBx62eyIY73Pa5CZiIaz3Y9r3QCC7ybyqSTOUhEk3+iPklSVdkczdSd2wkmoRUOt99EbzKRt8XjeImGGdfpGfIA5mPAvsiY9OXTf0ftSILywFriOwdxegQwXVSZc1UCVxL6SU94rioOzYUTtAhYpn3iB5cxAC8Iq3AW8sAvLVagbeWAXhrtQJvLQPw1moF3loGMHVgoFMVUXBgAD8ssy4i4ggH7sZwMMUMdg4e63aCzX51bzhUUx1zMkmmY2NOQrm8eGWwRCyMPGGOmCtWopL/F90xvd2G80TPAAAAAElFTkSuQmCC" />*/}
            <img className={styles.googleIcon} src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0IDEyQzI0IDE3Ljk4OTcgMTkuNjExNiAyMi45NTQyIDEzLjg3NSAyMy44NTQyVjE1LjQ2ODhIMTYuNjcxMUwxNy4yMDMxIDEySDEzLjg3NVY5Ljc0OTA2QzEzLjg3NSA4Ljc5OTg0IDE0LjM0IDcuODc1IDE1LjgzMDYgNy44NzVIMTcuMzQzOFY0LjkyMTg4QzE3LjM0MzggNC45MjE4OCAxNS45NzAzIDQuNjg3NSAxNC42NTczIDQuNjg3NUMxMS45MTY2IDQuNjg3NSAxMC4xMjUgNi4zNDg3NSAxMC4xMjUgOS4zNTYyNVYxMkg3LjA3ODEyVjE1LjQ2ODhIMTAuMTI1VjIzLjg1NDJDNC4zODg0NCAyMi45NTQyIDAgMTcuOTg5NyAwIDEyQzAgNS4zNzI4MSA1LjM3MjgxIDAgMTIgMEMxOC42MjcyIDAgMjQgNS4zNzI4MSAyNCAxMloiIGZpbGw9IiMxODc3RjIiLz4KPHBhdGggZD0iTTE2LjY3MTEgMTUuNDY4OEwxNy4yMDMxIDEySDEzLjg3NVY5Ljc0OTAyQzEzLjg3NSA4LjgwMDAzIDE0LjMzOTkgNy44NzUgMTUuODMwNiA3Ljg3NUgxNy4zNDM4VjQuOTIxODhDMTcuMzQzOCA0LjkyMTg4IDE1Ljk3MDUgNC42ODc1IDE0LjY1NzYgNC42ODc1QzExLjkxNjUgNC42ODc1IDEwLjEyNSA2LjM0ODc1IDEwLjEyNSA5LjM1NjI1VjEySDcuMDc4MTJWMTUuNDY4OEgxMC4xMjVWMjMuODU0MkMxMC43MzYgMjMuOTUgMTEuMzYyMSAyNCAxMiAyNEMxMi42Mzc5IDI0IDEzLjI2NCAyMy45NSAxMy44NzUgMjMuODU0MlYxNS40Njg4SDE2LjY3MTFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" />
        </div>
        <div className={styles.text}>
            <p className={styles.btnText}><b>Continue with Facebook</b></p>
        </div>
    </div>)
}
export default LoginWithFB