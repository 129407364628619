/**
 * Renders the feed component.
 *
 * @param containerRef - Reference to the container element.
 * @param feed - Array of feed objects.
 * @param feedLoading - Indicates if the feed is currently loading.
 * @param lastUpdated - Last updated timestamp.
 * @param moreExists - Indicates if there are more feed items available.
 * @param fetchMoreFeed - Function to fetch more feed items.
 * @param setFeed - Function to set the feed items.
 * @param isProfile - Indicates if the feed is for a user profile.
 */

import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import FeedHeader from "../feedHeader";
import { handleLike, isLiked } from "app/components/HandleLike";

import { DirectusImage, FeedObject } from "app/core/common";
import { useUser } from "app/context/userInfo";
import TimeAgo from "app/helpers/timeago";
import styles from "./feed.module.scss";

import like_icon from "../../../assets/icons/like.svg";
import liked_icon from "../../../assets/icons/liked.svg";
import comment_icon from "../../../assets/icons/comment.svg";
import { useHook } from "app/hooks/common";
import { useFeed } from "app/context/feedContext";
import DetailView from "./DetailView";
import { GetProfileFeed } from "app/api/profile.api";
import { Trans } from "react-i18next";
import Button from "../button/button";
import { formatNumber, truncate } from "app/helpers/helper";

// Icons
import axios from "axios";
import Modal from "../Modal";
import { GatedContentCheck } from "./gatedContent";
import { CreateContent } from "../createContent/createContent";

export default function Feed({
  containerRef,
  feed,
  feedLoading,
  lastUpdated,
  moreExists,
  fetchMoreFeed,
  setFeed,
  isProfile,
  isUserProfile,
}: {
  containerRef: any;
  feed: FeedObject[];
  feedLoading: boolean;
  lastUpdated: any;
  moreExists: boolean;
  fetchMoreFeed: () => void;
  setFeed: (feed: FeedObject[]) => void;
  isProfile?: boolean;
  isUserProfile?: boolean;
}) {
  const history = useHistory();
  const location = useLocation();
  const { cookie, userInfo } = useHook();
  const [itemIndex, setItemIndex] = useState(0);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [currentTime, setCurrentTime] = useState(Date.now());

  /* ================== */
  // Load more feed
  /* ================== */
  const loadMore = () => {
    //console.log("loadMore")
    fetchMoreFeed();
  };

  /* ================== */
  // Restore the scroll position
  /* ================== */
  // Restore the scroll position after exiting fullscreen
  const onFullScreenChange = (event) => {
    const scrollPosition = localStorage.getItem("feedPosition");
    window.scrollTo(0, parseInt(scrollPosition, 10));
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("mozfullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener("mozfullscreenchange", onFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        onFullScreenChange
      );
    };
  }, []);

  // Restore the scroll position after returning to the feed page
  useEffect(() => {
    if (location.pathname === "/" && !isProfile) {
      const scrollPosition = localStorage.getItem("feedPosition");
      console.log("scrollPosition: " + parseInt(scrollPosition));
      if (containerRef.current) {
        document.documentElement.scrollTo(0, parseInt(scrollPosition, 10));
      }
    }
  }, [location]);

  // Save the scroll position when scrolling
  const onScroll = () => {
    const { scrollTop, scrollHeight } = document.documentElement;

    // Save the scroll position
    if (!isProfile && !document.fullscreenElement) {
      localStorage.setItem("feedPosition", scrollTop.toString());
    }

    if (
      moreExists &&
      !feedLoading &&
      window.innerHeight + scrollTop >= scrollHeight - 500
    ) {
      console.log("loadMore");
      return loadMore();
    }
    return false;
  };

  /**********************/
  // Intersection Observer
  /**********************/
  const userPausedRef = useRef(false);
  useEffect(() => {
    const container = containerRef.current;
    window.addEventListener("scroll", onScroll);

    // Intersection Observer to pause videos when out of view and play when in view
    const videos = container.querySelectorAll("video");
    let currentPlayingVideo = null;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry: any) => {
          const video = entry.target;
          if (entry.isIntersecting && !showDetailModal) {
            if (video !== currentPlayingVideo && !userPausedRef.current) {
              if (currentPlayingVideo) {
                currentPlayingVideo.pause();
              }
              video.play();
              currentPlayingVideo = video;
            }
          } else if (video === currentPlayingVideo || showDetailModal) {
            if (!userPausedRef.current) {
              video.pause();
              currentPlayingVideo = null;
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    // Add observer to all video elements
    videos.forEach((video) => {
      observer.observe(video);

      video.addEventListener("play", () => {
        userPausedRef.current = false;
      });
      video.addEventListener("pause", () => {
        userPausedRef.current = true;
      });
      video.addEventListener("ended", () => {
        // Video has ended, reset currentTime to restart
        video.currentTime = 0;
        video.play();
      });
    });

    return () => {
      window.removeEventListener("scroll", onScroll);
      observer.disconnect();
    };
  });

  /**********************/
  // Single-tap like action
  /**********************/
  const handleSingleTap = (itemId) => {
    if (userInfo) {
      if (!isLiked(itemId, feed)?.liked) {
        handleHeartOverlay(itemId);
      }
      handleLike({ itemId, setItems: setFeed, cookie, userInfo });
    }
  };

  /**********************/
  // Double-tap like action
  /**********************/
  /*   const handleDoubleTap = (itemId) => {
    console.log("currentTime: " + currentTime);
    console.log("lastTapTime: " + lastTapTime);
    if (currentTime - lastTapTime < doubleClickThreshold) {
      if (!isLiked(itemId, feed)?.liked) {
        handleHeartOverlay(itemId);
      }
      handleLike({ itemId, setItems: setFeed, cookie, userInfo });
    }
    setLastTapTime(currentTime);
    setTimeout(() => {
      setLastTapTime(0);
    }, doubleClickThreshold); // Adjust the duration as needed
  }; */

  /**********************/
  // Handle heart overlay
  /**********************/
  const handleHeartOverlay = (itemId) => {
    const imageContainer = document.querySelector(
      `.imageContainer-${itemId}`
    ) as HTMLElement;
    const heartIcon = imageContainer.querySelector(
      ".heart-icon"
    ) as HTMLElement;

    heartIcon.style.opacity = "1"; // Show the heart icon
    heartIcon.style.display = "block"; // Show the heart icon
    setTimeout(() => {
      heartIcon.style.opacity = "0"; // Hide the heart icon
      heartIcon.style.display = "none"; // Show the heart icon
    }, 800); // Adjust the duration as needed
  };

  useEffect(() => {
    const bodyContent = document.getElementById("bodyContent");

    if (bodyContent) {
      if (showDetailModal) {
        // prevent scrolling when modal is open
        document.body.classList.add("no-scroll");
      } else {
        // restore scrolling when modal is closed
        document.body.classList.remove("no-scroll");
        document.body.style.overflow = "auto";
      }

      return () => {
        // cleanup
        document.body.style.overflow = "auto";
      };
    }
  }, [showDetailModal]);

  return (
    <>
      {showDetailModal && (
        <DetailView
          show={showDetailModal}
          toggleModal={setShowDetailModal}
          index={itemIndex}
          setIndex={setItemIndex}
          items={feed}
          setItems={setFeed}
          currentTime={currentTime}
          feedLoading={feedLoading}
          moreExists={moreExists}
          fetchMoreFeed={fetchMoreFeed}
        />
      )}
      <div
        className={`feed ${
          isProfile ? `${styles.feed} ${styles.profile_feed}` : `${styles.feed}`
        }`}
        id="feed"
      >
        {feed.length == 0 && !feedLoading ? (
          <div className="card">
            <h4 style={{textAlign: "center"}}>
              {isUserProfile ? (
                <Trans>noFeedProfileMessage</Trans>
              ) : isProfile ? (
                <Trans>noFeedUserMessage</Trans>
              ) : (
                <Trans>noFeedMessage</Trans>
              )}
            </h4>
            {!isProfile && (
              <Link to="/clubs/">
                <Button className="btn_sm btn_solid btn_centered">
                  VIP Clubs
                </Button>
              </Link>
            )}
          </div>
        ) : feed.length == 0 && feedLoading ? (
          <div className={styles.feed_item_loading}>
            <div className="loader" />
          </div>
        ) : (
          <>
            {feed.map((item, index) => {
              return (
                <div className={styles.feed_item} key={index}>
                  <div className={styles.content}>
                    <div className={styles.creator}>
                      <Link to={`/${item.artist.id}/`}>
                        <img src={item?.artist?.avatar ? DirectusImage(item?.artist?.avatar) : "/blank.svg"} />
                      </Link>
                    </div>
                    <div>
                      <b>
                        <Link to={`/${item.artist.id}/`}>
                          {item.artist.first_name}
                        </Link>
                        :
                      </b>{" "}
                      <p>{TimeAgo(item.date_created)}</p>
                    </div>
                    {item.visibility == "Private" && (
                      <div className={styles.visibility}>
                        <Trans>exclusive</Trans>
                      </div>
                    )}
                  </div>

                  <div className={styles.post_content}>
                    <p>
                      {" "}
                      <span
                      /* onClick={() => {
                          setItemIndex(index);
                          setShowDetailModal(true);
                        }} */
                      >
                        {item.visibility === "Private" ? (
                          item.access ? (
                            truncate(item?.content, 150, "...more", () => {
                              setItemIndex(index);
                              setShowDetailModal(true);
                            })
                          ) : (
                            <GatedContentCheck
                              creator={item.artist}
                              content={truncate(item.content, 90, "")}
                            />
                          )
                        ) : (
                          truncate(item.content, 150, "...more", () => {
                            setItemIndex(index);
                            setShowDetailModal(true);
                          })
                        )}
                      </span>
                    </p>

                    <div
                      className={`imageContainer-${item.id} ${styles.media}`}
                      /* onDoubleClick={() => item.type != "video" && handleDoubleTap(item.id)}
                        onTouchStart={() => item.type != "video" && handleDoubleTap(item.id)} */
                      data-id={item.id}
                    >
                      <>
                        {(item.visibility === "Private" && item.access) ||
                        item.visibility === "Public" ? (
                          item.type == "video" ? (
                            <ReactPlayer
                              url={DirectusImage(item.image.id)}
                              controls={true}
                              playsinline
                              //playing
                              muted // Add the muted attribute
                              //autoplay
                              //loop
                              onProgress={(state) => {
                                setCurrentTime(state.playedSeconds);
                              }}
                              pip={false}
                              width="100%"
                              height="100%"
                            />
                          ) : (
                            <>
                              <img src={DirectusImage(item.image.id)} />
                              <div
                                className={styles.overlay}
                                onClick={() => {
                                  setItemIndex(index);
                                  setShowDetailModal(true);
                                }}
                              />
                            </>
                          )
                        ) : (
                          <></>
                        )}
                      </>
                      <div
                        className={`heart-icon ${styles.heart}`}
                        data-id={item.id}
                      />
                    </div>
                  </div>
                  {(item.visibility === "Private" && item.access) ||
                  item.visibility === "Public" ? (
                    <>
                      <div className={styles.actions}>
                        {/* Likes */}
                        <div
                          className={`${styles.action} ${
                            item.liked ? styles.liked : ""
                          }`}
                        >
                          <img
                            alt={`like-${item.id}`}
                            src={item.liked ? liked_icon : like_icon}
                            onClick={() => handleSingleTap(item.id)}
                          />{" "}
                          {/* <p>Liked Post</p>  */}
                          <span>{formatNumber(item.likes)}</span>
                        </div>
                        {/* Comments */}
                        <div
                          className={styles.action}
                          onClick={() => {
                            setItemIndex(index);
                            setShowDetailModal(true);
                          }}
                        >
                          <img alt={`${item.id}`} src={comment_icon} />{" "}
                          {/* <p>Comments</p> */}{" "}
                          <span>{formatNumber(item.comments)}</span>
                        </div>
                      </div>

                      {/* <div
                        onClick={() => {
                          setItemIndex(index);
                          setShowDetailModal(true);
                        }}
                        className={styles.comments}
                      >
                        View All Comments
                      </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
            {moreExists && feedLoading && <div className="loader" />}
          </>
        )}
      </div>
    </>
  );
}
