import Cookie from "js-cookie";
import useConnectWallet from "app/Loop_Reuseable_Component/packages/loop_wallet/hooks/useConnectWallet";
import { useUser } from "app/context/userInfo";
import { isCreator } from "app/core/common";

export const useHook = () => {
  const {
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList,
  } = useUser();
  const _isCreator = isCreator(userInfo?.role);

  const cookie = Cookie.get("cookie");
  const address = Cookie.get("address");

  return {
    address,
    cookie,
    userInfo,
    checkingUserInfo,
    followingListLoaded,
    followingList,
    isCreator: _isCreator,
    myNfts,
    myNFTsLoading,
    loadMoreNFTs,
    updateMyNFTs,
    updateFollowingList,
  };
};
