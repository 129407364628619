import React, { Component, useEffect, useState } from 'react';
import styles from './gallery.module.scss';
import { DirectusImage } from 'app/core/common';

export default function Gallery({
  launchpadNFT,
  index,
  imageIndex,
  setImageIndex
}: {
  launchpadNFT: any;
  index: number;
  imageIndex: number;
  setImageIndex: any;
}) {
  const [gallery, setGallery] = useState([]);

  const _gallery = [];

  useEffect(() => {
    // Clear Gallery
    setGallery([]);
    // Create Gallery
    launchpadNFT?.info?.launchpad_type[index]?.type_gallery?.map((r: any) => {
      _gallery.push({
        original: DirectusImage(r.directus_files_id.id),
        thumbnail: DirectusImage(r.directus_files_id.id),
      });
    });
    setGallery(_gallery);
  }, [launchpadNFT, index]);

  return (
    <div className={styles.block}>
      {gallery.length > 1 && (
        <div className={styles.slider}>
          {gallery?.map((image, index) => {
            return (
              <div
                className={styles.slider_img}
                onClick={() => setImageIndex(index)}
              >
                <img src={image?.thumbnail} />
              </div>
            );
          })}
        </div>
      )}
      <div className={styles.daynamicSlider}>
        <div className={styles.activeImage}>
          <img src={gallery[imageIndex]?.original} />
        </div>
      </div>
    </div>
  );
}
