import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useHook } from "app/hooks/common";
import { ProfileData, isCreator } from "app/core/common";
import styles from "./Profile.module.scss";

export const ProfileTabs = ({
  user,
  tabName,
  handleTab,
}: {
  user: ProfileData;
  tabName: string;
  handleTab: (name: string) => void;
}) => {
  const location = useLocation();
  const userId = location.pathname.split("/")[1];
  const { userInfo } = useHook();
  const isUserProfile = userInfo?.id === userId;

  const tabs = [
    {
      slug: "collections",
      name: <Trans>collectionTab</Trans>,
      creator: true,
    },
    {
      slug: "courses",
      name: <Trans>courses</Trans>,
      hidden: user?.access_courses ? false : true,
      creator: true,
    },
    {
      slug: "gallery",
      name: user?.id === "5c1f2716-ce35-48dd-a0de-a884f502592a" ? "My Offers" : <Trans>gallery</Trans>,
      creator: true,
    },
    {
      slug: "events",
      name: <Trans>events</Trans>,
      creator: true,
    },
  ];

  return (
    <div className={styles.tabs}>
      {
        <>
          <div
            className={`${styles.tab} ${
              tabName == "posts" ? styles.active : ""
            }`}
            onClick={() => handleTab("posts")}
          >
            {isCreator(user?.role) ? (
              <Trans>creatorPost</Trans>
            ) : (
              <Trans>posts</Trans>
            )}
          </div>

          {tabs?.map((item, index) => {
            return (
              <div
                className={`${styles.tab} ${
                  tabName == item.slug ? styles.active : ""
                }`}
                hidden={item.creator != !!isCreator(user?.role) || item.hidden}
                onClick={() => handleTab(item.slug)}
              >
                {item.name}
              </div>
            );
          })}

          {isUserProfile && (
            <>
              <div
                className={`${styles.tab} ${
                  tabName == "nfts" ? styles.active : ""
                }`}
                onClick={() => handleTab("nfts")}
              >
                <Trans>myNFTs</Trans>
              </div>
            </>
          )}
        </>
      }
    </div>
  );
};
