import { useEffect, useRef, useState } from "react";
import styles from "./nfts.module.scss";
import { Link } from "react-router-dom";
import * as api from "app/api/nfts.api";
import { useHook } from "app/hooks/common";
import { IPFSImage, NFT } from "app/core/common";
import Loader from "app/components/Loader";
import { truncate } from "app/helpers/helper";
import { Trans } from "react-i18next";

export default function ProfileNfts({ containerRef }) {
  const { address, myNfts, myNFTsLoading, loadMoreNFTs } = useHook();

  /**********************/
  // scroll event callback function
  /**********************/
  const onScroll = () => {
    const { scrollTop, scrollHeight } = document.documentElement;
    //console.log("scrollHeight", scrollHeight);

    if (
      !myNFTsLoading &&
      window.innerHeight + scrollTop >= scrollHeight - 1200
    ) {
      return loadMoreNFTs();
    }
    return false;
  };

  /**********************/
  // Scroll Event
  /**********************/
  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <>
      <p onClick={() => { window.location.reload() }} className={styles.notification}><Trans>nftMessage</Trans></p>

      <div className={styles.nfts}>
        {myNFTsLoading && myNfts.length === 0 ? (
          <Loader />
        ) : (
          myNfts?.map((item, index) => {
            return (
              <div key={index} className={styles.card}>
                <Link to={`/nftDetail/${item.id}`} key={index}>
                  <div className={styles.courseImage}>
                    <img src={IPFSImage(item?.image, true)} />
                  </div>
                  <div className={styles.details}>
                    <p className={styles.title}>{item?.name}</p>
                    <p className={styles.description}>
                      {truncate(item?.description, 150)}
                    </p>
                  </div>
                </Link>
              </div>
            );
          })
        )}
      </div>

      {myNFTsLoading && myNfts.length > 0 && <Loader />}
    </>
  );
}
