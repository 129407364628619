import { useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";

import Button from "app/components/button/button";
import { Link, useLocation } from "react-router-dom";

import * as api from "app/api/collections.api";
import {
  CourseItems,
  LaunchPadNFT,
  getParam,
  CurrencyRateType,
  DirectusImage,
} from "app/core/common";
import Gallery from "app/components/ImageGallery/gallery";
import { useHook } from "app/hooks/common";
import LaunchpadForm from "./mint.form";
import ProjectInfo from "./projectInfo";
import { setParam } from "app/helpers/helper";
import UpcomingForm from "./upcoming.form";
import Modal from "app/components/Modal";
import handleTransaction from "app/hooks/handleTransaction";
import { Trans } from "react-i18next";
import ProjectDesc from "./projectDesc";

import cc_icon from "assets/icons/cc_icon.svg";
import wompi_icon from "assets/icons/wompi_icon.svg";
import Project_Gallery from "./projectGallery";
import Project_Video from "./projectVideo";
import FaqLayout from "app/components/faqLayout";

function scrollToSection(anchor: string): void {
  const section = document.getElementById(anchor);
  if (section) {
    section.scrollIntoView({ behavior: "smooth" });
  }
}

export default function ProfileHeader() {
  const location = useLocation();
  const launchpadName = location.pathname.split("/")[2];
  const { userInfo, updateMyNFTs } = useHook();
  const address = userInfo && userInfo.wallet_address;
  const [launchpadNFT, setLaunchpadNFT] = useState<LaunchPadNFT>();
  const [loaded, setLoaded] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [pausedModal, setPausedModal] = useState(false);
  const [paymentProvider, setPaymentProvider] = useState("wompi");

  const [type, setType] = useState<number>(1);
  const [mintCount, setMintCount] = useState(1);
  const [checked, setChecked] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [referral, setReferral] = useState("");
  const { MintNft } = handleTransaction();

  const launchpad_imageData =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.type_gallery?.[
      imageIndex
    ]?.directus_files_id;
  const collection_desc =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.collection
      ?.description;
  const mintableCount =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1].launchInfo?.mint_limit;
  const contractAddr =
    launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.fan_collection?.address;

  const paramType = getParam("type");

  const [price, symbol] = launchpadNFT?.info?.launchpad_type[
    Number(type) - 1
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USD"];

  const [currencyRate, setCurrencyRate] = useState<CurrencyRateType>(null);
  const [paymentUrl, setPaymtentUrl] = useState("");

  // Get Type
  useEffect(() => {
    if (paramType) {
      setType(paramType);
    }
  }, [paramType]);

  // Get Launchpad NFT
  useEffect(() => {
    if (!launchpadNFT && !loaded) {
      api
        .FetchLaunchPad(launchpadName === "bienbebida" ? "gringotuesdays" : launchpadName)
        .then((launchpadNFT) => setLaunchpadNFT(launchpadNFT));
      setLoaded(true);
    }
  }, [launchpadNFT, launchpadName, loaded]);

  // Get Currency Rate
  useEffect(() => {
    if (launchpadNFT) {
      api
        .FetchCurrencyRate({ code: symbol === "COP" ? "USD" : "COP" })
        .then((currency) => setCurrencyRate(currency));
    }
  }, [launchpadNFT]);

  /***************/
  /* Payment Modal */
  /***************/
  const generate_paymentUrl = useCallback(async () => {
    const launchpad_id = launchpadNFT?.info?.id;
    const collection_name = launchpadNFT?.info.project_name;
    const mintPrice =
      symbol === "COP"
        ? price * mintCount * 100
        : (currencyRate?.value * (price * mintCount) * 100).toFixed(0);
    setPaymentLoading(true);
    //setPaymentModal(false);
    try {
      await api
        .generatePaymentUrl({
          wallet_addr: address,
          user: userInfo.id,
          launchpad_id: launchpad_id,
          collection_name: collection_name,
          collection_addr: contractAddr,
          collection_desc: collection_desc,
          payment_amount: Number(mintPrice),
          number_of_nfts: mintCount,
          referral: referral,
          provider: paymentProvider,
        })
        .then((url: string) => {
          if (url) {
            setPaymtentUrl(url);
            window.location.href = url;
          } else {
            setPaymentLoading(false);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [
    paymentUrl,
    launchpadNFT,
    address,
    mintCount,
    currencyRate,
    paymentProvider,
    referral,
    userInfo,
  ]);

  const mintHandler = () => {
    if (launchpadNFT?.info?.mint_status !== "active") {
      setPausedModal(true);
    } else {
      MintNft({
        collection_addr: contractAddr,
        address: address,
        redirectTo: `/${userInfo.id}/nfts`,
        mint_type: "free_claim",
        type_name: launchpadNFT?.info?.launchpad_type[Number(type) - 1]?.fan_collection?.name,
      })
        .then((res) => {
          if (res !== null) {
            updateMyNFTs();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (currencyRate?.value === null) {
    return;
  }

  return (
    <div className={styles.launchpad}>
      {/* <Link className={styles.back} to={`/${launchpadNFT?.info?.artist?.id}`}> */}
      {launchpadNFT && (
        <div className={styles.back} onClick={() => window.history.back()}>
          <img
            src="/back.svg"
            alt="back_icon"
            style={{ marginRight: "10px" }}
          />{" "}
          back
          {/* {launchpadNFT?.info?.artist?.first_name} */}
        </div>
      )}

      {(launchpadNFT?.info?.project_slug !== "gringotuesdays") && (launchpadNFT?.info?.launchpad_type.length > 1) && (
        <div className={styles.typeSection}>
          <div className={styles.types}>
            {launchpadNFT?.info?.launchpad_type?.map((r: any, i: number) => {
              return (
                <div
                  key={i}
                  className={type === i + 1 ? styles.type_active : styles.type}
                  onClick={() => {
                    setType(i + 1);
                    setParam("type", i + 1);
                  }}
                >
                  {r?.collections_type?.name}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {launchpadNFT ? (
        <>
          <div className={styles.launchViewHeader}>
            <div className={styles.launchViewLeft}>
              <div className={styles.nft_view}>
                <Gallery
                  launchpadNFT={launchpadNFT}
                  index={Number(type) - 1}
                  imageIndex={imageIndex}
                  setImageIndex={setImageIndex}
                />
              </div>
            </div>
            <div className={styles.launchViewRight}>
              {launchpadNFT?.info?.project_status == "live" && (
                <LaunchpadForm
                  launchpadNFT={launchpadNFT.info}
                  currencyRate={currencyRate}
                  launchpad_imageData={launchpad_imageData}
                  index={Number(type) - 1}
                  setPaymentModal={setPaymentModal}
                  mintHandler={mintHandler}
                />
              )}
              {launchpadNFT?.info?.project_status == "upcoming" && (
                <UpcomingForm
                  launchpadNFT={launchpadNFT.info}
                  currencyRate={currencyRate}
                  launchpad_imageData={launchpad_imageData}
                  index={Number(type) - 1}
                />
              )}
            </div>
          </div>

          {/* =========== About =========== */}
          <ProjectDesc info={launchpadNFT.info} index={Number(type) - 1} />

          {/* =========== Project Info =========== */}
          <ProjectInfo
            info={launchpadNFT.info}
            index={Number(type) - 1}
            setPaymentModal={setPaymentModal}
            mintHandler={mintHandler}
          />

          {/* =========== Gallery =========== */}
          <Project_Gallery
            gallery={
              launchpadNFT?.info?.launchpad_type[0].fan_collection?.gallery[0]
                ?.gallery_items
            }
          />

          {/* =========== Fan Club Media =========== */}
          <Project_Video
            creator={launchpadNFT.info.artist.first_name}
            video_id={
              launchpadNFT?.info?.launchpad_type[0].fan_collection?.video_id
            }
          />

          {/* =========== Faqs =========== */}
          <FaqLayout faqs={launchpadNFT?.info?.launchpad_type[0].fan_collection.faqs} />
          
          <Modal
            isOpen={paymentModal}
            title=""
            onClose={() => setPaymentModal(false)}
            modalContentClass={styles.modalContent}
            blur={true}
          >
            <div className={styles.paymentModal}>
              <div className={styles.modalHeader}>
                <img
                  src={DirectusImage(
                    launchpadNFT?.info?.launchpad_type[Number(type) - 1]
                      ?.fan_collection.icon.id
                  )}
                />
                <h6>
                  {
                    launchpadNFT?.info?.launchpad_type[Number(type) - 1]
                      .fan_collection.name
                  }
                </h6>
              </div>
              <div className={styles.modalBody}>
                <div className={styles.items}>
                  <div className={styles.item}>
                    <div className={styles.left}>{/* NFTs */}</div>
                    <div className={styles.right}>
                      <div className={styles.input}>
                        <button
                          id="minus"
                          className={styles.minus}
                          disabled={!address || mintCount <= 1}
                          onClick={() => {
                            setMintCount(mintCount > 1 ? mintCount - 1 : 0);
                          }}
                        >
                          {" "}
                          -{" "}
                        </button>
                        <input
                          type="text"
                          disabled={!address}
                          value={mintCount}
                          placeholder="0"
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setMintCount(
                              value < 0
                                ? 0
                                : value <= mintableCount
                                ? value
                                : mintableCount
                            );
                          }}
                        />
                        <button
                          id="plus"
                          className={styles.plus}
                          disabled={!address || mintCount === mintableCount}
                          onClick={() => {
                            const value = mintCount + 1;
                            setMintCount(
                              value < 0
                                ? 0
                                : value <= mintableCount
                                ? value
                                : mintableCount
                            );
                          }}
                        >
                          {" "}
                          +{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.left}>
                      Total {/*  · 0% */}
                      {/* <ToolTip content={". . . ."}>
                    <img src={infoIcon} />
                  </ToolTip> */}
                    </div>
                    <div
                      className={styles.right}
                      style={{ textAlign: "right" }}
                    >
                      ${(price * mintCount).toLocaleString()} {symbol}
                      <br />
                      <span>
                        $
                        {currencyRate
                          ? (currencyRate?.value * (price * mintCount)).toFixed(
                              2
                            )
                          : "..."}{" "}
                        {/* {currencyRate?.value * (price * mintCount)}{" "} */}
                        {currencyRate?.code}
                      </span>
                    </div>
                  </div>

                  <hr />
                  <div className={styles.document} style={{ margin: "20px 0" }}>
                    <label>
                      <Trans>referral</Trans>{" "}
                      {
                        launchpadNFT?.info?.launchpad_type[Number(type) - 1]
                          .fan_collection.name
                      }{" "}
                      by {launchpadNFT?.info?.artist?.first_name}?{" "}
                    </label>
                    <div className={styles.input_lg}>
                      <input
                        type="text"
                        disabled={!address}
                        value={referral}
                        onChange={(e) => {
                          setReferral(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  {/* Terms of Service and Data Privacy */}
                  {(launchpadNFT?.info?.has_tos ||
                    launchpadNFT?.info?.has_policy) && (
                    <div className={styles.checkbox_item}>
                      <div className={styles.right}>
                        <div className={styles.checkbox}>
                          <input
                            type="checkbox"
                            id="squaredFour"
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                          />
                          <label htmlFor="squaredFour"></label>
                        </div>
                      </div>
                      <div className={styles.left}>
                        <span style={{ textAlign: "left" }}>
                          <Trans>agreeMessage</Trans>{" "}
                          {launchpadNFT?.info?.has_tos && (
                            <>
                              <b>
                                <a
                                  href={launchpadNFT?.info?.tos_link}
                                  target="_blank"
                                >
                                  <Trans>termsOfServiceSm</Trans>
                                </a>
                              </b>
                            </>
                          )}{" "}
                          {launchpadNFT?.info?.has_policy && (
                            <>
                              {launchpadNFT?.info?.has_tos && (
                                <>
                                  <>and</>{" "}
                                </>
                              )}
                              <b>
                                <a
                                  href={launchpadNFT?.info?.policy_link}
                                  target="_blank"
                                >
                                  <Trans>privacyPolicySm</Trans>
                                </a>
                              </b>
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className={styles.paymentProviders}>
                  <div className={styles.title}>
                    <label>
                      <Trans>chooseProvider</Trans>
                    </label>
                  </div>
                  <div className={styles.providers}>
                    <div
                      className={`${styles.provider} ${
                        paymentProvider === "wompi"
                          ? styles.provider_active
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        id="wompi"
                        name="provider"
                        value="wompi"
                        checked={paymentProvider === "wompi"}
                        onChange={(e) => setPaymentProvider(e.target.value)}
                      />
                      <label htmlFor="wompi">
                        <img src={wompi_icon} /> Wompi
                      </label>
                    </div>
                    <div
                      className={`${styles.provider} ${
                        paymentProvider === "stripe"
                          ? styles.provider_active
                          : ""
                      }`}
                    >
                      <input
                        type="radio"
                        id="stripe"
                        name="provider"
                        value="stripe"
                        checked={paymentProvider === "stripe"}
                        onChange={(e) => setPaymentProvider(e.target.value)}
                      />
                      <label htmlFor="stripe">
                        <img src={cc_icon} />
                        Credit Card
                      </label>
                    </div>
                  </div>
                </div>
                <Button
                  className={styles.buy}
                  disabled={
                    !address ||
                    mintCount === 0 ||
                    (!checked &&
                      (launchpadNFT?.info?.has_policy ||
                        launchpadNFT?.info?.has_tos))
                  }
                  onClick={() => {
                    generate_paymentUrl();
                  }}
                >
                  <Trans>proceedToPay</Trans>
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={pausedModal}
            title=""
            onClose={() => setPausedModal(false)}
            customClass="pauseModal"
          >
            <div
              style={{ textAlign: "center", marginBottom: "50px" }}
              className="pauseCard"
            >
              <h3>
                Mint is currently paused, minting will continue again soon
              </h3>
            </div>
          </Modal>
        </>
      ) : (
        <div className="loader" />
      )}
    </div>
  );
}
