import { Trans } from 'react-i18next';
import Modal from '../Modal';
import styles from './notification.module.scss';
import { useHistory } from 'react-router-dom';
import Button from '../button/button';

const InProgressError = ({ setTheme, redirectURL }: { setTheme: any, redirectURL: string | undefined; }) => {
  const history = useHistory();
  function onClose() {
    setTheme({
      type: undefined,
      subType: undefined,
    });
    if (redirectURL) {
      history.push(redirectURL);
      redirectURL === "/" && window.location.reload();
    } else {
      window.location.reload();
    }
  }

  return (
    <Modal isOpen={true} title='' onClose={() => onClose()} zIndex={4} center>
      <div className={styles.notification_content}>
        <div className={styles.image_content}>
          <p className={styles.title}><Trans>TransactionDelayTitle</Trans></p>
          <div style={{ textAlign: 'center', marginBottom: '50px' }}>
            <Trans>TransactionDelayMessage</Trans>
          </div>
          <div>
          <Button className='btn_full btn_centered btn_solid' onClick={() => onClose()}>
            Click here to visit your profile now
          </Button>
        </div>
        </div>
      </div>
    </Modal>
  );
};

export default InProgressError;
