const GlobalsTranslation = [
  {
    en: {
      translations: {
        browserNotice: "Access Loop Fans",
        embeddedWebView: "Oh no! This page doesnt work well inside Instagram. Please open in your browser to continue",
        embeddedWebViewStep1: "Locate the three dots (...)",
        embeddedWebViewStep2: "Select (Open in external browser)",
        embeddedWebViewStep3: "Tap the button to copy the website link and paste it into your browser",

        // Menu Items
        home: "Home",
        forCreators: "For Creators",
        exclusive: "Exclusive",
        chatButton: "Chat With Us",
        faqs: "FAQs",

        // Gated Content
        gatedContentClubOnGoing: "To gain access to this exclusive content, you need to join the creator's VIP Club by purchasing a VIP Pass from a creators collection.",
        gatedContentClubExpired: "VIP Passes for this club are no longer available. Please check back later for new VIP Passes.",

        // Profile Tabs
        collectionTab: "Digital Passes",

        //Login/Signup Modal
        loginTitle: "Loop Fans Member Login",
        signupTitle: "Join as Loop Fans Member",
        signupSubTitle: "Get free access to explore VIP Clubs and special benefits",
        signupCheckList: [
          "Access exclusive content, media and courses",
          "Be the first to hear about new launches",
          "Get notified of bonuses, giveaways and comps",
          "Receive discounts to tickets and merch",
        ],

        // Common
        createContent: {
          placeholder: "What's on your mind?!",
          post: "Create Post",
        },
        mintNote: "Note: NFT art selection is minted by random",
        feedUpdateText: "updated",
        notifications: "Notifications",
        profile: "Profile",
        fanClubHeading: "Join VIP Clubs to access exclusive collections and content",
        discoverHeading: "Discover upcoming and live NFT drops from your favorite creators",
        myNFTs: "My Digital Passes",
        nftMessage: "New passes may take 1-3 minutes to show up, If your pass isn't showing up yet click here to refresh.",
        courses: "Courses",
        collections: "Collections",
        browseCollecitons: "Browse Collections",
        posts: "Posts",
        creatorPost: "Creator Posts",
        backToAlbums: "Back to Albums",
        viewAlbum: "View Album",
        gallery: "Gallery",
        events: "Events",
        following: "Following",
        follow: "Follow",
        benefits: "Benefits",
        collectionType: "Collection Type",
        pausedCap: "PAUSED",
        mintNowCap: "CLAIM NOW",
        buyNowCap: "BUY NOW",
        creator: "Creator",
        endDate: "End Date",
        price: "Price",
        termsOfServiceSm: "ToS",
        privacyPolicySm: "Privacy Policy",
        proceedToPay: "Proceed to Pay",
        agreeMessage: "I agree to the",
        documentId: "Document ID",
        referral: "Who told you about",
        noFeedMessage: "Your feed is feeling a bit lonely! Head over to clubs and start following some creators to fill it with awesome content. Your personalized experience awaits!",
        noFeedUserMessage: "This user hasn't posted anything yet. Be the first to discover and connect with their future updates!",
        noFeedProfileMessage: "You haven't posted anything yet. Share your first post to get the ball rolling!",
        noGalleryMessage: "{{user}} hasn't posted any albums yet. Check back later to discover and connect with their future updates!",
        noEventMessage: "{{user}} hasn't created any events yet. Check back later to discover and connect with their future updates!",
        showQrCode: "Show QR Code",
        viewBenefits: "View Benefits",
        viewCreatorContent: "View Creator’s Content",
        optional: "Optional",
        chooseProvider: "Select Payment Type",
        free: "Free",
        ended: "Ended",

        // Courses
        introduction: "Introduction",
        classes: "Classes",

        // Collections
        about: "About",
        collection: "Collection",
        viewCollection: "View Collection",
        fanClubMedia: "Access the best moments of",

        // Events
        eventMessage: "To gain access to the <b>{{event_name}}</b> event you must purchase a MultiPass NFT from <b>{{creator_name}}</b>.",
        eventEnded: "This event has ended.",
        eventSoldOut: "This event is sold out.",
        eventLocation: "Location",
        eventDate: "Date and Time",


        // Transaction Status
        transactionApproved: "Transaction Approved",
        transactionPurchaseError: "There was an error processing your transaction. Please try again.",
        transactionTryAgain: "Try Again",
        transactionMintPlural: "Congratulations! Your collectibles have been successfully created.",
        transactionMintSingular: "Congratulations! Your collectible has been successfully created.",
        transactionMyNFTs: "My Digital Passes",
        transactionMessage1: "Communicating with network",
        transactionMessage2: "Waiting for confirmation",
        transactionMessage3: "Fetching transaction mint status",
        TransactionDelayTitle: "Your Digital Pass is being Created",
        TransactionDelayMessage: "may take a moment to show up in your wallet.",
      },
    },
    es: {
      translations: {
        browserNotice: "Se detectó el navegador de la aplicación",
        embeddedWebView: "¡Oh no! Esta página no funciona bien dentro de Instagram. Abra en su navegador para continuar",
        embeddedWebViewStep1: "Localice los tres puntos (...)",
        embeddedWebViewStep2: "Seleccione (Abrir en navegador externo)",
        embeddedWebViewStep3: "Toque el botón para copiar el enlace del sitio web y péguelo en su navegador",

        // Menu Items
        home: "Home",
        forCreators: "Para Creadores",
        exclusive: "Exclusivo",
        chatButton: "Chatea con nosotros",
        faqs: "Preguntas frecuentes",

        // Gated Content
        gatedContentClubOnGoing: "Para acceder a este contenido exclusivo, debe unirse al Club VIP del creador comprando un Pase VIP de una colección de creadores.",
        gatedContentClubExpired: "Los pases VIP para este club ya no están disponibles. Vuelva más tarde para ver los nuevos pases VIP.",

        // Profile Tabs
        collectionTab: "Pases Digitales",

        //Login/Signup Modal
        loginTitle: "Inicio de sesión de miembro de Loop Fans",
        signupTitle: "Únete como miembro de Loop Fans",
        signupSubTitle: "Obtenga acceso gratuito para explorar clubes VIP y beneficios especiales",
        signupCheckList: [
          "Acceda a contenido, medios y cursos exclusivos",
          "Sé el primero en enterarte de los nuevos lanzamientos",
          "Recibe notificaciones de bonificaciones, regalos y competiciones",
          "Recibe descuentos en entradas y mercancía",
        ],

        // Common
        createContent: {
          placeholder: "¿Qué tienes en mente?!",
          post: "Crear publicación",
        },
        mintNote: "Nota: selección de arte NFT acuñada al azar",
        feedUpdateText: "actualizado",
        notifications: "Notificaciones",
        profile: "Perfil",
        fanClubHeading: "Únete a los clubes NFT para acceder a colecciones y contenido exclusivos",
        discoverHeading: "Descubre colecciones próximas y en vivo de tus creadores favoritos",
        myNFTs: "Mis NFTs",
        nftMessage: "Las nuevas colecciones pueden tardar de 1 a 3 minutos en aparecer. Si su colección aún no aparece, haga clic aquí para actualizar.",
        courses: "Cursos",
        collections: "Colecciones",
        browseCollecitons: "Examinar colecciones",
        posts: "Publicaciones",
        creatorPost: "Publicaciones del creador",
        backToAlbums: "Volver a los álbumes",
        viewAlbum: "Ver álbum",
        gallery: "Galería",
        events: "Eventos",
        following: "Siguiendo",
        follow: "Seguir",
        benefits: "Beneficios",
        collectionType: "Tipo de colección",
        pausedCap: "PAUSADO",
        mintNowCap: "ACUÑAR AHORA",
        buyNowCap: "COMPRAR AHORA",
        creator: "Creador",
        endDate: "Fecha de finalización",
        price: "Precio",
        termsOfServiceSm: "ToS",
        privacyPolicySm: "Política de privacidad",
        proceedToPay: "Proceder al pago",
        agreeMessage: "Estoy de acuerdo con los",
        documentId: "Documento de Identidad",
        referral: "¿Quién te recomendó",
        noFeedMessage: "¡Tu feed se siente un poco solo! Dirígete a los clubes y comienza a seguir a algunos creadores para llenarlo con contenido increíble. ¡Tu experiencia personalizada te espera!",
        noFeedUserMessage: "Este usuario aún no ha publicado nada. ¡Sé el primero en descubrir y conectarte con sus futuras actualizaciones!",
        noFeedProfileMessage: "Todavía no has publicado nada. ¡Comparte tu primera publicación para empezar a rodar la pelota!",
        noGalleryMessage: "{{user}} aún no ha publicado ningún álbum. Vuelve más tarde para descubrir y conectarte con sus futuras actualizaciones!",
        noEventMessage: "{{user}} aún no ha creado ningún evento. Vuelve más tarde para descubrir y conectarte con sus futuras actualizaciones!",
        showQrCode: "Mostrar código QR",
        viewBenefits: "Ver Beneficios",
        viewCreatorContent: "Ver contenido del creador",
        optional: "Opcional",
        chooseProvider: "Seleccione el tipo de pago",
        free: "Gratis",
        ended: "Finalizado",

        // Courses
        introduction: "Introducción",
        classes: "Clases",

        // Collections
        about: "Acerca de",
        collection: "Colección",
        viewCollection: "Ver colección",
        fanClubMedia: "Accede a los mejores momentos de",

        // Events
        eventMessage: "Para acceder al evento <b>{{event_name}}</b> debe comprar un NFT MultiPass de <b>{{creator_name}}</b>.",
        eventEnded: "Este evento ha finalizado.",
        eventSoldOut: "Este evento está agotado.",
        eventLocation: "Ubicación",
        eventDate: "Fecha y hora",

        // Transaction Status
        transactionApproved: "Transacción Aprobada",
        transactionPurchaseError: "Hubo un error procesando tu transacción. Por favor intenta nuevamente.",
        transactionTryAgain: "Inténtalo de nuevo",
        transactionMintPlural: "¡Felicitaciones! Sus coleccionables se han creado correctamente.",
        transactionMintSingular: "¡Felicitaciones! Su coleccionable se ha creado correctamente.",
        transactionMyNFTs: "Mis NFTs",
        transactionMessage1: "Comunicando con la red",
        transactionMessage2: "Esperando confirmación",
        transactionMessage3: "Obteniendo estado de acuñación de transacción",
        TransactionDelayTitle: "Su Pase Digital se está creando",
        TransactionDelayMessage: "puede tardar un momento en aparecer en su billetera.",
      },
    },
  },
];

export default GlobalsTranslation;
