import { useEffect, useRef, useState } from "react";
import styles from "./Details.module.scss";

import { Link } from "react-router-dom";
import { CourseIntro, CourseUrl } from "app/core/common";

export default function Details({ intro }: { intro: CourseIntro }) {
  return (
    <>
      <div>
        <div className="breadcrumb flat">
          <Link to={`/${intro?.course_id?.creator?.id}/`} className="active">
            {intro?.course_id?.creator?.first_name
              ? intro?.course_id?.creator?.first_name
              : "..."}
          </Link>
          <Link to={`/courses/${CourseUrl(intro?.course_id?.id)}/`} className="active">
            {intro?.course_id?.title ? intro?.course_id?.title : "..."}
          </Link>
          <Link to="#">{intro?.title}</Link>
        </div>
        <div className={styles.section} style={{ marginTop: "20px" }}>
          <h3 className={styles.title}>About this Course</h3>
          <p dangerouslySetInnerHTML={{ __html: intro?.description }} />
        </div>

        <div className={styles.section} style={{ marginTop: "100px" }}>
          <h5 className={styles.title}>What you'll learn</h5>
          <p>
            <ul>
              <li>1. Aliquam in varius urna, nec congue tortor.</li>
              <li>2. Aliquam in varius urna, nec congue tortor.</li>
              <li>3. Aliquam in varius urna, nec congue tortor.</li>
              <li>4. Aliquam in varius urna, nec congue tortor.</li>
            </ul>
          </p>
        </div>
      </div>
    </>
  );
}
