import styles from "./index.module.scss";
import { Trans } from "react-i18next";

const Project_Video = ({
  creator,
  video_id,
}: {
  creator: string;
  video_id: any;
}) => {
  return (
    video_id && (
      <div className={styles.fan_media}>
        <div className={styles.info_header}>
          <h6>
            Fan Club Media
          </h6>
          <span>
            <Trans>fanClubMedia</Trans> {creator}
          </span>
        </div>
        <div className={styles.gallery_items}>
          <iframe
            className={styles.loop_player}
            src={`https://www.youtube.com/embed/${video_id}?autoplay=false&controls=1&showinfo=0&modestbranding=1&rel=0`}
            title="Loop Fans"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; modestbranding;"
            allowFullScreen
          ></iframe>
        </div>
        <div className={styles.divider} />
      </div>
    )
  );
};

export default Project_Video;
