import { useHook } from "app/hooks/common";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";
/* import { Trans } from "react-i18next"; */

const Additional_info = ({
  info,
  index,
  setPaymentModal,
  mintHandler,
}: {
  info?: any;
  index: number;
  setPaymentModal?: any;
  mintHandler?: any;
}) => {
  //const { address } = useRecoilValue(walletState);
  const { address } = useHook();

  return (
    <div className={styles.benefits_bg}>
      <div>
        <div className={styles.benefits_type}>
          <div className={styles.info_header}>
            <h6>
              <Trans>benefits</Trans>
            </h6>
          </div>
          <div>
            {info?.launchpad_type[index]?.benefits?.benefit.map(
              (r: any, i: number) => {
                return (
                  <>
                    <div className={styles.benefit} key={i}>
                      <div className={styles.img}>
                        <img
                          src={
                            "https://loop-markets.directus.app/assets/" + r?.image
                          }
                        />
                      </div>
                      <div className={styles.desc}>
                        <b>{r?.name}</b>
                        <br />
                        <span>{r?.desc}</span>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
        <div className={styles.divider} />
      </div>

      {/* <div className={styles.benefits_type}>
        <div className={styles.policy}>
          {info?.has_tos && (
            <a href={info?.tos_link} target="_blank">
              <button className={styles.tos}>
                <>termsOfService</>
              </button>
            </a>
          )}
          {info?.has_policy && (
            <a href={info?.policy_link} target="_blank">
              <button className={styles.tos}>
                <>privacyPolicy</>
              </button>
            </a>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default Additional_info;
