import { useEffect, useState } from "react";

import axios from "axios";
import styles from "./widget.module.scss";

import whatsAppIcon from "./assets/whatsapp.svg";
import whatsAppIconOpen from "./assets/whatsapp-open.svg";

import chatIcon from "./assets/chatwidget.svg";
import chatIconClose from "./assets/chatwidget-close.svg";
import { Trans } from "react-i18next";

export default function ChatWidget() {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.ChatWidget}>
      <div
        className={styles.chatCard}
        // add transition
        style={
          open
            ? {
                bottom: "100px",
                opacity: "1",
                visibility: "visible",
                transition: "ease-in-out 500ms",
                transform: "translateY(0)",
              }
            : {
                opacity: "0",
                visibility: "hidden",
                transition: "ease-in-out 500ms",
                transform: "translateY(10%)", // Change this value to slide in/out vertically
              }
        }
      >
        <img src={chatIcon} alt="Open Chat" />
        {/* <p><Trans>chatMessage</Trans></p> */}
        <a href="https://wa.me/61430345336" target="_blank">
          <button className={styles.btn}>
            <img src={whatsAppIcon} alt="Whatsapp" />
            <Trans>chatButton</Trans>
          </button>
        </a>
      </div>

      {open ? (
        <button onClick={() => setOpen(false)}>
          <img src={chatIconClose} alt="Close Chat" />
        </button>
      ) : (
        <button onClick={() => setOpen(true)}>
          <img src={whatsAppIconOpen} alt="Open Chat" />
        </button>
      )}
    </div>
  );
}
