import { useHook } from "app/hooks/common";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";
import { Redeemable } from "app/core/common";
import { useState } from "react";
import Modal from "app/components/Modal";
import QRCode from "react-qr-code";
/* import { Trans } from "react-i18next"; */

const Redeemables = ({ redeemables }: { redeemables: Redeemable[] }) => {
  //const { address } = useRecoilValue(walletState);
  const { address } = useHook();
  const [showQrCodeModal, setShowQrCodeModal] = useState(
    Array(redeemables.length).fill(false)
  );

  return (
    <div className={styles.benefits_bg} id="benefits">
      <div>
        <div className={styles.benefits_type}>
          <div className={styles.info_header}>
            <h6>
              <Trans>benefits</Trans>
            </h6>
          </div>
          <div>
            {redeemables.length > 0 && (
              <>
                {redeemables.map((redeemable, index) => {
                  return (
                      <div key={index} className={styles.benefit}>
                        {/* Fake QR Code */}
                        <div
                          className={styles.qr_placeholder}
                          onClick={() => {
                            const newModalsState = [...showQrCodeModal];
                            newModalsState[index] = !newModalsState[index];
                            setShowQrCodeModal(newModalsState);
                          }}
                        >
                          <img
                            className={styles.img}
                            src={
                              redeemable.image_url
                                ? redeemable.image_url
                                : "/placeholder_qrcode.jpg"
                            }
                          />
                          <div className={styles.qr_placeholder_text}>
                            <Trans>showQrCode</Trans>
                          </div>
                        </div>
                        {/* Real QR Code in Modal */}
                        <Modal
                          isOpen={showQrCodeModal[index]}
                          onClose={() => {
                            const newModalsState = [...showQrCodeModal];
                            newModalsState[index] = false;
                            setShowQrCodeModal(newModalsState);
                          }}
                          title={`${redeemable.name}`}
                        >
                          <QRCode
                            size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                              padding: "15px",
                              backgroundColor: "white",
                            }}
                            className="qr-code"
                            value={redeemable.qr_hash}
                            viewBox={`0 0 256 256`}
                          />
                        </Modal>
                        <div className="redeemable-text-container">
                          <p className={styles.desc}>
                            <b>{redeemable.name}</b>
                          </p>
                          <p className="redeemable-description">
                            {redeemable.description}
                          </p>
                        </div>
                      </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className={styles.divider} />
      </div>
    </div>
  );
};

export default Redeemables;
