import { useState } from "react";
import styles from "./index.module.scss";

import share from "assets/fans/share.svg";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import CountdownComp from "./countdown";
import {
  CurrencyRateType,
  DirectusImage,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import Modal from "app/components/Modal";
import { Link } from "react-router-dom";
import Button from "app/components/button/button";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import { LaunchPadNFTQueryInfo } from "app/api/common.api";

const curDate = new Date().toISOString();

const NftInfo = ({
  info,
  hasRedeemables,
  owner,
}: {
  info?: LaunchPadNFTQueryInfo;
  hasRedeemables: boolean;
  owner: boolean;
}) => {
  /* const { MintNft } = handleFansTransaction(); */
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { address } = useHook();

  return (
    <div>
      <>
        <div className={styles.nft_view}>
          <div className={styles.header}>
            <h3>{info.metadata.name}</h3>
            {/* Creator Info Section */}
            <div className={styles.artist}>
              <div className={styles.profile}>
                <div>
                  <span>
                    <Trans>collection</Trans>
                  </span>
                  <p>{info.metadata.project}</p>
                </div>
              </div>
              {/* <div className={styles.divider} />
              <div className={styles.profile}>
                <div>
                  <span>
                    <Trans>collection</Trans>
                  </span>
                  <p>{info.metadata.project}</p>
                </div>
              </div> */}
            </div>

            {owner && hasRedeemables && (
              <Button
                className={styles.buttonBenefits}
                onClick={() =>
                  document.getElementById("benefits").scrollIntoView()
                }
              >
                <Trans>viewBenefits</Trans>
              </Button>
            )}

            <hr />

            {owner && (
              <Link to={`/${info.collection.artist.id}`}>
                <Button className={styles.button}>
                  <Trans>viewCreatorContent</Trans>
                </Button>
              </Link>
            )}

            {/* NFT Mint Section */}
            {/* {mintStarted ? (
              <>
                <div className={styles.mint_live}>
                  <div>
                    <p className={styles.price_text}>
                      <Trans>price</Trans>
                    </p>
                    <p className={styles.price_value}>
                      {price == 0
                        ? `Free`
                        : `$${Number(price).toLocaleString()} ${symbol}`}
                    </p>
                    <p className={styles.convert} hidden={price == 0}>
                      $
                      {currencyRate
                        ? (currencyRate?.value * (price * 1)).toFixed(2)
                        : "..."}
                      <span> {currencyRate && currencyRate?.code}</span>
                    </p>
                  </div>
                  {mintEnded ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <>
                      {!address ? (
                        <>
                          <Button onClick={() => setShowLoginModal(true)}>
                            Login
                          </Button>
                        </>
                      ) : (
                        <button
                          disabled={
                            !address || launchpadNFT?.mint_status !== "active"
                          }
                          onClick={() =>
                            price == 0 ? mintHandler() : setPaymentModal(true)
                          }
                          className={styles.buy}
                        >
                          {launchpadNFT?.mint_status !== "active" ? (
                            <Trans>pausedCap</Trans>
                          ) : price == 0 ? (
                            <Trans>mintNowCap</Trans>
                          ) : (
                            <Trans>buyNowCap</Trans>
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <EndDate />
              </>
            ) : (
              <div className={styles.mint_live}>
                <span className={styles.countdown}>
                  <CountdownComp
                    launchpadNFT={launchpadNFT}
                    index={Number(index)}
                  />
                </span>
              </div>
            )} */}

            {/* <h3>
              {launchpadNFT?.launchpad_type[Number(index)].collection.name}
            </h3>
            <p
              className={styles.about}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].collection
                    .description,
              }}
            /> */}
          </div>
        </div>
      </>
    </div>
  );
};

export default NftInfo;
