import axios from "axios";

export async function GetProfile({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/profile/${id}`,
    method: "get",
    data: {},
  });

  return result.data;
}

export async function GetProfileGallery({ id, cookie, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}albums/${id}`,
    method: "post",
    data: {
      cookie,
      userInfo,
    },
  });

  return result.data;
}

export async function GetProfileEvents({ id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/events/${id}`,
    method: "post",
    data: { id: id },
  });

  return result.data;
}

export async function GetProfileFeed({ id, page, userInfo }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}arena/profile/feed`,
    method: "post",
    data: {
      id: id,
      page: page,
      userInfo: userInfo,
    },
  });

  return result.data;
}

// Follow/UnFollow
export async function followAction({ cookie, userInfo, follower_id }) {
  const result = await axios({
    url: `${process.env.REACT_APP_MIDDLEWARE}user/action/follow`,
    method: "post",
    data: {
      cookie: cookie,
      userInfo: userInfo,
      follower_id: follower_id,
    },
  });

  return result.data;
}
