import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import logo from "../assets/logo.svg";
import caret from "../assets/caret.svg";
import Button from "app/components/button/button";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import { useUser } from "app/context/userInfo";
import { isCreator } from "app/core/common";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import LanguageSelector from "app/util/LanguageSelector";
import { DirectusImage } from "../app/core/common";
import { truncate } from "app/helpers/helper";
import { useLogin } from "app/context/loginProvider";

export default function Header() {
  const { logout } = useWallet();
  const { userInfo } = useHook();
  const location = useLocation();
  const [notiDrawer, setNotiDrawer] = useState(false);
  const [searchDrawer, setSearchDrawer] = useState(false);

  const { showSignup, toggleLoginModal, toggleSignupModal } = useLogin();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const origin = window.location.origin;
  const pathname = window.location.pathname;

  {
    /* add more actions here... */
  }
  const actions = [
    {
      name: <Trans>profile</Trans>,
      to: `/${userInfo?.id}/`,
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <div className="container">
          <div className={styles.items}>
            <div className={styles.left}>
              <div className={styles.logo_section}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img src={logo} className={styles.logo} alt="logo" />
                    {/* <span>FANS</span> */}
                  </Link>
                </div>
              </div>

              <div>
                <ul className={styles.nav}>
                  <li>
                    <a href="https://www.loop.do">
                      <Trans>home</Trans>
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://www.loop.fans/stars">
                      <Trans>forCreators</Trans>
                    </a>
                  </li> */}
                  {/* <li><a href="https://www.loop.fans/clubs">VIP Clubs</a></li> */}
                </ul>
              </div>
            </div>

            <div className={styles.right}>
              <div className={styles.actions}>
                <LanguageSelector />
              </div>
              {userInfo ? (
                <div
                  className={styles.profile}
                  style={dropdownOpen ? { borderRadius: "25px 25px 0 0" } : {}}
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  <img
                    src={
                      userInfo?.avatar
                        ? DirectusImage(userInfo?.avatar)
                        : "/blank.svg"
                    }
                    alt=""
                  />
                  <p>{truncate(userInfo?.first_name, 10, "...")}</p>

                  <div className={styles.dropdown}>
                    <img src={caret} />
                  </div>
                  {dropdownOpen && (
                    <div className={styles.dropdown_content}>
                      {actions.map((action) => {
                        return (
                          <>
                            <Link
                              to={action.to}
                              onClick={() => setDropdownOpen(false)}
                            >
                              {action.name}
                            </Link>
                          </>
                        );
                      })}
                      <Link
                        to="#"
                        onClick={() => {
                          logout();
                          //window.Bugpilot.logout();
                          window.location.reload();
                        }}
                      >
                        Logout
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  <Button
                    className={styles.loginButton}
                    onClick={() => {
                      toggleLoginModal();
                      toggleSignupModal(false);
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    className={styles.signupButton}
                    onClick={() => {
                      toggleLoginModal();
                      toggleSignupModal(true);
                    }}
                  >
                    Join Now
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
