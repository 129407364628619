import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Carousel from "app/components/carousel/Carousel";
import { Link } from "react-router-dom";
import { DirectusImage, CourseItems } from "app/core/common";
import { truncate } from "app/Loop_Reuseable_Component/packages/loop_wallet/lib/text";
import { t } from "i18next";

export default function CourseList({ course }: { course: CourseItems }) {
  const url = course.title.toLowerCase().replace(/\s/g, "-");
  return (
    <div>
      <div className="breadcrumb flat">
        <Link to={`/${course?.creator.id}/`} className="active">
          {course?.creator.first_name}
        </Link>
        <Link to={`/courses/${course?.id}`}>{course?.title}</Link>
      </div>
      <div className={styles.section}>
        <Carousel
          title={t("introduction")}
          items={course.introduction.map((item, index) => {
            return {
              link: `/courses/${url}/intro/${item.id}/`,
              title: item.title,
              desc: truncate(item.excerpt, 300),
              image: DirectusImage(item?.placeholder?.id),
            };
          })}
        />
      </div>

      <div className={styles.section}>
        <Carousel
          title={t("classes")}
          items={course.classes.map((item, index) => {
            return {
              link: `/courses/${url}/class/${item.id}/`,
              title: item.title,
              desc: truncate(item.excerpt, 300),
              image: DirectusImage(item?.placeholder?.id),
            };
          })}
        />
      </div>
    </div>
  );
}
