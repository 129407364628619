import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";

import { useHistory, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import play_video from "../../../../assets/icons/play_video.svg";
import pause_video from "../../../../assets/icons/pause_video.svg";
import restart_video from "../../../../assets/icons/restart_video.svg";

import rewind_10 from "../../../../assets/icons/rewind_10.svg";
import forward_10 from "../../../../assets/icons/forward_10.svg";

import * as api from "app/api/courses.api";
import { DirectusImage, CourseClass } from "app/core/common";
import Details from "./Details";

export default function Course() {
  const [startPlaying, setStartPlaying] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);
  const [seekPosition, setSeekPosition] = useState(0);
  const playerRef = useRef(null);

  const location = useLocation();
  const introId = location.pathname.split("/")[4];
  const [intro, setIntro] = useState<CourseClass>();

  useEffect(() => {
    api.getCourseClass({ id: introId }).then((data) => {
      setIntro(data[0]);
    });
  }, []);

  useEffect(() => {
    const player = playerRef.current;
    if (player) {
      const unsubscribeProgress = player.on("progress", handleProgress);
      const unsubscribeDuration = player.on("duration", handleDuration);
      return () => {
        unsubscribeProgress();
        unsubscribeDuration();
      };
    }
  }, []);

  const handleProgress = (progressState) => {
    setCurrentTime(progressState.playedSeconds);
    setDuration(progressState.loadedSeconds);
  };

  const handleDuration = (durationState) => {
    setDuration(durationState);
  };

  const handleSeekMouseDown = () => {
    setIsSeeking(true);
  };

  const handleSeekMouseMove = (event) => {
    if (isSeeking) {
      const seekBar = event.currentTarget;
      const seekBarRect = seekBar.getBoundingClientRect();
      const seekBarWidth = seekBarRect.width;
      const clickPosition = event.clientX - seekBarRect.left;
      const seekPercentage = clickPosition / seekBarWidth;
      const seekTime = seekPercentage * duration;
      setSeekPosition(seekTime);
    }
  };

  const handleSeekMouseUp = () => {
    if (isSeeking) {
      setIsSeeking(false);
      playerRef.current.seekTo(seekPosition);
      setSeekPosition(0);
    }
  };

  const handlePlay = () => {
    const player = playerRef.current;
    if (player) {
      playing ? setPlaying(false) : setPlaying(true);
    }
  };

  const handleSeek = (seconds) => {
    const player = playerRef.current;
    if (player) {
      player.seekTo(seconds);
    }
  };

  const handleSeekClick = (event) => {
    const seekBar = event.currentTarget;
    const seekBarRect = seekBar.getBoundingClientRect();
    const seekBarWidth = seekBarRect.width;
    const clickPosition = event.clientX - seekBarRect.left;
    const seekPercentage = clickPosition / seekBarWidth;
    const seekTime = seekPercentage * playerRef.current.getDuration();
    playerRef.current.seekTo(seekTime);
  };

  useEffect(() => {
    if (startPlaying) {
      setPlaying(true);
    }
  }, [startPlaying]);

  return (
    <div className={styles.course}>
      {intro ? (
        <>
          <div
            className={styles.header}
            style={
              startPlaying
                ? {
                    backgroundColor: "#000",
                  }
                : {
                    backgroundImage: `url(${DirectusImage(
                      intro.placeholder.id
                    )})`,
                  }
            }
          >
            {startPlaying ? (
              <div className={styles.player_wrapper}>
                <ReactPlayer
                  url={DirectusImage(intro.video.id)}
                  ref={playerRef}
                  onProgress={handleProgress}
                  controls={false}
                  playsinline
                  playing={playing}
                  autoplay
                  loop
                  width="100%"
                  height="100%"
                />
                <div className={styles.player_controls}>
                  <button onClick={handlePlay}>
                    {playing ? (
                      <img src={pause_video} />
                    ) : (
                      <img src={play_video} />
                    )}
                  </button>
                  {/* Rewind */}
                  <button onClick={() => handleSeek(currentTime - 10)}>
                    <img src={rewind_10} />
                  </button>
                  {/* Forward */}
                  <button onClick={() => handleSeek(currentTime + 10)}>
                    <img src={forward_10} />
                  </button>
                  <div
                    className={`${styles.seek_bar} ${
                      isSeeking ? styles.grabbing : ""
                    }`}
                    onClick={handleSeekClick}
                    onMouseDown={handleSeekMouseDown}
                    onMouseMove={handleSeekMouseMove}
                    onMouseUp={handleSeekMouseUp}
                  >
                    <div
                      className={styles.seek_bar_filled}
                      style={{
                        width: `${
                          ((seekPosition ? seekPosition : currentTime) /
                            duration) *
                          100
                        }%`,
                      }}
                    >
                      {
                        <div
                          className={styles.seek_bar_dragged}
                          style={{
                            left: `${
                              ((seekPosition ? seekPosition : currentTime) /
                                duration) *
                              100
                            }%`,
                          }}
                        />
                      }
                    </div>
                  </div>
                  <button onClick={() => handleSeek(0)}>
                    <img src={restart_video} />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.overlay} />
                <div className={styles.content}>
                  <img src={play_video} onClick={() => setStartPlaying(true)} />
                </div>
              </>
            )}
          </div>
          <div className={styles.body} id="course">
            <Details intro={intro} />
          </div>
        </>
      ) : (
        <>
          <div className="loader" />
        </>
      )}
    </div>
  );
}
