import { useState } from "react";
import styles from "./index.module.scss";

import share from "assets/fans/share.svg";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import CountdownComp from "./countdown";
import {
  CurrencyRateType,
  DirectusImage,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import Modal from "app/components/Modal";
import { Link } from "react-router-dom";
import Button from "app/components/button/button";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import { useLogin } from "app/context/loginProvider";

const curDate = new Date().toISOString();

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const LaunchpadForm = ({
  queryResult,
  launchpadNFT,
  currencyRate,
  launchpad_imageData,
  index,
  setPaymentModal,
  mintHandler,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT: LaunchPadNFT;
  currencyRate: CurrencyRateType;
  launchpad_imageData?: any;
  index?: Number;
  setPaymentModal?: any;
  mintHandler?: any;
}) => {
  /* const { MintNft } = handleFansTransaction(); */
  const { toggleLoginModal, toggleSignupModal } = useLogin();
  const { address } = useHook();

  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USDC"];
  /* const { balance } = useJunoTokenBalance(symbol); */

  /*   const contractAddr =
    launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.NFT; */

  const mintStarted =
    curDate >=
    new Date(
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.startDate
    ).toISOString();

  const mintEnded =
    curDate >=
    new Date(
      launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate +
        " " +
        launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endTime
    ).toISOString();

  /* const [mintCount, setMintCount] = useState(1); */

  const EndDate = () => {
    return (
      <p
        className={styles.endDate}
        style={{ textAlign: "center", paddingTop: "10px" }}
      >
       {launchpadNFT?.project_slug === "gringotuesdays" ? "Limited Time Only" : <p><Trans>endDate</Trans>:{" "}</p>} 
        <b>
          {launchpadNFT?.project_slug !== "gringotuesdays" && (<CountdownComp launchpadNFT={launchpadNFT} index={Number(index)} />)}
          {/* {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCDate()}{" "}
          {
            monthNames[
              new Date(
                launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
              ).getUTCMonth()
            ]
          }{" "}
          {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCFullYear()} */}
        </b>
      </p>
    );
  };

  return (
    <div>
      <>
        <div className={styles.project_social}>
          {/* <ShareButton name={launchpadNFT?.project_name} icon={share} /> */}
        </div>

        <div className={styles.nft_view}>
          <div className={styles.header}>
            <h6>
              {launchpadNFT?.launchpad_type[Number(index)].collections_type?.display_name || launchpadNFT?.launchpad_type[Number(index)].fan_collection.name}
            </h6>

            {/* Creator Info Section */}
            <div className={styles.artist}>
              <Link to={`/${launchpadNFT.artist.id}`}>
                <div className={styles.profile}>
                  <img
                    src={DirectusImage(launchpadNFT?.artist.avatar.id)}
                    alt="thumb"
                  />
                  <div>
                    <span>
                      <Trans>creator</Trans>
                    </span>
                    <p>{launchpadNFT?.artist.first_name}</p>
                  </div>
                </div>
              </Link>
              <div className={styles.divider} />
              <div>
                <span>
                  <Trans>collectionType</Trans>
                </span>
                <p style={{textTransform: "capitalize" }}>
                  <>{launchpadNFT?.collection_type}</>
                </p>
              </div>
            </div>

            {/* NFT Mint Section */}
            {mintStarted ? (
              <>
                <div className={styles.mint_live}>
                  <div>
                    <p className={styles.price_text}>
                      <Trans>price</Trans>
                    </p>
                    <p className={styles.price_value}>
                      {price == 0
                        ? `Free`
                        : `$${Number(price).toLocaleString()} ${symbol}`}
                    </p>
                    <p className={styles.convert} hidden={price == 0}>
                      $
                      {currencyRate
                        ? (currencyRate?.value * (price * 1)).toFixed(2)
                        : "..."}
                      <span> {currencyRate && currencyRate?.code}</span>
                    </p>
                  </div>
                  {mintEnded ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <>
                      {!address ? (
                        <>
                          <Button
                            onClick={() => {
                              toggleLoginModal();
                              toggleSignupModal(true);
                            }}
                          >
                            Login
                          </Button>
                        </>
                      ) : (
                        <button
                          disabled={
                            !address || launchpadNFT?.mint_status !== "active"
                          }
                          onClick={() =>
                            price == 0 ? mintHandler() : setPaymentModal(true)
                          }
                          className={styles.buy}
                        >
                          {launchpadNFT?.mint_status !== "active" ? (
                            <Trans>pausedCap</Trans>
                          ) : price == 0 ? (
                            <Trans>mintNowCap</Trans>
                          ) : (
                            <Trans>buyNowCap</Trans>
                          )}
                        </button>
                      )}
                    </>
                  )}
                </div>
                <EndDate />
              </>
            ) : (
              <div className={styles.mint_live}>
                <span className={styles.countdown}>
                  <CountdownComp
                    launchpadNFT={launchpadNFT}
                    index={Number(index)}
                  />
                </span>
              </div>
            )}

            {/* <h3>
              {launchpadNFT?.launchpad_type[Number(index)].collection.name}
            </h3>
            <p
              className={styles.about}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].collection
                    .description,
              }}
            /> */}

            {/* Warn user that NFT will be random */}
            {launchpadNFT?.launchpad_type[Number(index)]?.type_gallery.length >
              1 && (
              <p style={{ textAlign: "center", color: "#677599" }}>
                <Trans>mintNote</Trans>
              </p>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default LaunchpadForm;
