import { Card, Grid } from "semantic-ui-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import styles from "./discover.module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { truncate } from "app/helpers/helper";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { CreatorCollections, DirectusImage } from "app/core/common";
export default function DiscoverView() {
  const [collections, setCollections] = useState<CreatorCollections[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}arena/collections`
        );
        const data = await response.data;
        setCollections(data);
        setLoading(true);
      } catch (err) {
        // Handle any errors
        setLoading(true);
      }
    }
    if (!loading) {
      fetchData();
    }
  }, [!collections, loading]);

  return (
    <div className={styles.discover}>
      <div className={styles.container}>
        <Grid columns="one" divided>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.column}>
              <h3>Discover</h3>
              <p className={styles.subHeading}>
                <Trans>discoverHeading</Trans>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {collections.length > 0 ? (
          <div>
            <div className={styles.collection}>
              {collections?.map((item, index) => {
                return (
                  <Link
                    to={`/collection/${item?.project_slug}`}
                    className={styles.item}
                    key={index}
                  >
                    <LazyLoadImage
                      alt={`${item.id}`}
                      effect="blur"
                      src={
                        process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS +
                        item?.banner?.id
                      }
                    />
                    <div className={styles.overlay} />
                    <Card.Content className={styles.content}>
                      <div
                        className={
                          item?.project_status === "live"
                            ? styles.type
                            : `${styles.type} ${styles.type_upcoming}`
                        }
                      >
                        <span>{item?.project_status}</span>
                      </div>
                      <Card.Header>
                        <div className={styles.club_details}>
                          <p className={styles.details}>
                            {truncate(item?.project_name, 25)}
                            {/* <p className={styles.content}>
                              {truncate(item?.launchpad_type.collection?.description, 100)}
                            </p> */}
                            <br />
                          </p>
                        </div>
                      </Card.Header>
                    </Card.Content>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="loader" />
        )}
      </div>
    </div>
  );
}
