import React, { useState } from "react";
import { Accordion, Container, Icon } from "semantic-ui-react";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";

export default function FaqLayout({ faqs }: { faqs: any }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  return (
    faqs && (
      <>
        <div className={styles.faqBody}>
          <h3 className={styles.heading}>
            <Trans>faqs</Trans>
          </h3>
          <div className={styles.faqs}>
            {faqs?.Questions?.map((r, i) => {
              return (
                <div
                  onClick={() =>
                    r.answer && setActiveIndex(activeIndex === i ? "" : i)
                  }
                  className={
                    r.answer
                      ? styles.Accordion
                      : `${styles.Accordion} ${styles.AccordionHeader}`
                  }
                >
                  <div
                    className={
                      r.answer && activeIndex === i ? styles.active : ""
                    }
                  >
                    <div className={styles.content}>
                      <div>
                        <h3>{r?.question}</h3>
                        {activeIndex === i && (
                          <div>
                            <hr />
                            <p
                              dangerouslySetInnerHTML={{ __html: r?.answer }}
                            />
                          </div>
                        )}
                      </div>
                      {r.answer && (
                        <img
                          className={styles.icon}
                          src={activeIndex === i ? "/close.svg" : "/expand.svg"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    )
  );
}
