import FeedHeader from "../../components/feedHeader";
import styles from "./feed.module.scss";
import { useFeed } from "app/context/feedContext";
import Feed from "app/components/feed/feed";
import { CreateContent } from "app/components/createContent/createContent";

export default function FeedHome({ containerRef }) {
  const {
    feed,
    feedLoading,
    lastUpdated,
    moreExists,
    fetchMoreFeed,
    fetchLatestFeed,
    setFeed,
  } = useFeed();

  return (
    <div className={styles.mainFeed}>
      <CreateContent feedRef={feed} updateFeedRef={setFeed} />
      <FeedHeader
        feedLoading={feedLoading}
        fetchLatestFeed={fetchLatestFeed}
        lastUpdated={lastUpdated}
      />
      <Feed
        feed={feed}
        feedLoading={feedLoading}
        lastUpdated={lastUpdated}
        moreExists={moreExists}
        fetchMoreFeed={fetchMoreFeed}
        setFeed={setFeed}
        containerRef={containerRef}
        isProfile={false}
      />
    </div>
  );
}
