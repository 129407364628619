import "./App.scss";
import Sidebar from "./sidebar/Sidebar";
import { Route, Switch } from "react-router-dom";
import Feed from "app/pages/Feed";
import { useWallet } from "app/Loop_Reuseable_Component/packages/loop_wallet";
import AuthenticatedRoute from "AuthenticatedRoute";
import LoginPage from "app/pages/Login/Login";
import Profile from "app/pages/Profiles/Profile";

import Course from "app/pages/Courses/index";
import CreatorCourse from "app/pages/Courses/Course";
import CourseIntro from "app/pages/Courses/Intro";
import CourseClass from "app/pages/Courses/Class";

import Collections from "app/pages/Collections/index";
import { NotificationContext } from "app/context/notification";
import { useCallback, useEffect, useRef, useState } from "react";
import NotificationModals from "app/components/notificationModals";
import TransactionStatus from "app/pages/TransactionStatus/status";
import ClubView from "app/pages/Clubs/ClubView";
import ScrollToTop from "ScrollToTop";
import NftDetail from "app/pages/NftDetail";
import DiscoverView from "app/pages/Discover/DiscoverView";
import { useHook } from "app/hooks/common";
import LoginModal from "app/components/loginModal/LoginModal";
import { useLogin } from "app/context/loginProvider";
import ChatWidget from "app/components/chatWidget/widget";
import Modal from "app/components/Modal";
import { Trans } from "react-i18next";
import Button from "app/components/button/button";
import { t } from "i18next";

interface BugpilotOptions {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  subscriptionStatus?: string;
}

/* declare global {
  interface Window {
    Bugpilot: {
      identify: (options: BugpilotOptions) => void;
      logout: () => void;
    };
  }
} */

function App() {
  const { address, userInfo } = useHook();
  const [type, setType] = useState(undefined);
  const [subType, setSubType] = useState(undefined);
  const [txHash, setTxHash] = useState(undefined);
  const [onOk, setOnOk] = useState(undefined);
  const [txErrorMessage, setTxErrorMessage] = useState(undefined);
  const [redirectURL, setRedirectURL] = useState("");
  const [themeData, setThemeData] = useState<any>({ theme: {} });

  const setTheme = useCallback((theme) => {
    setThemeData({ theme: theme });
    setType(theme?.type);
    setSubType(theme?.subType);
    setTxHash(theme?.txHash);
    setOnOk(theme?.onOk);
    setTxErrorMessage(theme?.txErrorMessage);
    if (theme?.redirectURL !== undefined) {
      setRedirectURL(theme?.redirectURL);
    }
  }, []);

  /* useEffect(() => {
    if (window.Bugpilot && userInfo) {
      window.Bugpilot.identify({
        id: userInfo.id,
        firstName: userInfo.first_name,
        subscriptionStatus: "active",
      });
    }
  }, [userInfo]); */

  const [state, setState] = useState<any>({
    theme: {
      type: undefined,
      subType: undefined,
      txHash: undefined,
      onOk: undefined,
      txErrorMessage: undefined,
      redirectURL: undefined,
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setTheme,
  });
  useEffect(() => {
    setState((prevState: any) => ({
      ...prevState,
      theme: {
        type,
        subType,
        txHash,
        onOk,
        txErrorMessage,
        redirectURL,
        ...themeData.theme,
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setTheme,
    }));
  }, [
    type,
    subType,
    txHash,
    onOk,
    txErrorMessage,
    redirectURL,
    setTheme,
    themeData.theme,
  ]);

  useEffect(() => {
    const handleResize = () => {
      const isKeyboardOpen = window.innerHeight < window.screen.height;

      if (isKeyboardOpen) {
        document.body.style.height = "100vh";
        // Other styling adjustments as needed
      } else {
        document.body.style.height = "auto";
        // Reset other styles
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerRef = useRef(null);
  const { showLoginModal, showSignup } = useLogin();

  /* Check UserAgent on iPhone */
  /*
    let standalone = (window.navigator as any).standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);
  */

  // check UserAgent on Android
  const ua = navigator.userAgent;
  const isInstagram = (ua.indexOf('Instagram') > -1) ? true : false;  

  const InAppModal = () => {
    const [copy, setCopy] = useState(false);
    {/* IN APP DETECTION */}
    return (
      <Modal
        isOpen={(isInstagram)}
        onClose={() => {}}
        title={t("browserNotice")}
        customClass="pauseModal"
      >
        <div
          style={{ textAlign: "center", marginBottom: "50px", padding: "20px" }}
          className="pauseCard"
        >
          <Trans>embeddedWebView</Trans>
          <br />
          <br />
          <p>
            <b>Step 1:</b> <Trans>embeddedWebViewStep1</Trans>
          </p>
          <p>
            <b>Step 2:</b> <Trans>embeddedWebViewStep2</Trans>
          </p>
          <br />
          <p>
            <b>or</b>
          </p>
          <br />
          <p>
            <Trans>embeddedWebViewStep3</Trans>
          </p>
          <br />
          <br />
          {/* Show Toast on button click */}
          <Button
            className="btn_full btn_sm btn_solid"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
              setCopy(true);
              setTimeout(() => {
                setCopy(false);
              }, 1000);
            }}
          >
            {!copy ? "Copy Link" : "Copied"}
          </Button>
          <br />
        </div>
      </Modal>
    );
  };

  return (
    <div className="App">
      <InAppModal />
      <ScrollToTop />
      <NotificationContext.Provider value={state}>
        <NotificationModals />
        <LoginModal showLoginModal={showLoginModal} showSignup={showSignup} />
        <Sidebar isLoggedIn={address} containerRef={containerRef}>
          <Switch>
            {/* Login Route */}
            <Route path="/login/" component={LoginPage} />

            {/* Clubs */}
            <Route path="/clubs/" exact component={ClubView} />

            {/* Discover */}
            <Route path="/discover/" exact component={DiscoverView} />

            {/* NFT Detail View */}
            <Route path="/nftDetail/:id" component={NftDetail} />

            {/* Courses Page */}
            <AuthenticatedRoute
              path="/courses/:id"
              exact
              component={Course}
              containerRef={containerRef}
            />
            <AuthenticatedRoute
              path="/courses/:id/intro/:id"
              exact
              component={CourseIntro}
              containerRef={containerRef}
            />
            <AuthenticatedRoute
              path="/courses/:id/class/:id"
              exact
              component={CourseClass}
              containerRef={containerRef}
            />
            <AuthenticatedRoute
              path="/courses/:name/:id"
              exact
              component={CreatorCourse}
              containerRef={containerRef}
            />

            {/* Collections Page */}
            <Route path="/collection/:id" exact component={Collections} />

            {/* Transaction Status */}
            <Route path="/transaction" exact component={TransactionStatus} />

            {/* Creator Profile */}
            <Route
              path="/:username/"
              exact
              render={() => <Profile containerRef={containerRef} />}
            />
            <Route
              path="/:username/:id"
              exact
              render={() => <Profile containerRef={containerRef} />}
            />

            {/* Feed */}
            <AuthenticatedRoute
              path="/"
              exact
              component={Feed}
              containerRef={containerRef}
            />
          </Switch>
        </Sidebar>
        <ChatWidget />
      </NotificationContext.Provider>
    </div>
  );
}

export default App;
