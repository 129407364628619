import axios from "axios";
const hostname = window.location.hostname;
const checkHost = hostname === "nft-juno.loop.markets";

/*=====================*/
/*  API for Creator Collections */
/*=====================*/
export async function getCreatorCollections({ username }) {
  try {
    const result = await axios({
      url: `${process.env.REACT_APP_MIDDLEWARE}arena/collections/${username}`,
      method: "get",
      data: {},
    });

    return result.data;
  } catch (error) {
    console.error("Error", error.message);
    // Handle the error here
  }
}

/*=====================*/
/*  API for Currency Rate */
/*=====================*/
export async function FetchCurrencyRate({ code }) {
  const result = await axios.get(
    `${process.env.REACT_APP_MIDDLEWARE}currency/rate/${code}`
  );
  return result.data;
}

/*=====================*/
/*  API for Launchpad */
/*=====================*/
export async function FetchLaunchPad(name: string) {
  try {
    const result = await axios({
      url: `${process.env.REACT_APP_MIDDLEWARE}fans/fans_launchpad/${name}`,
      method: "get",
      data: {},
    });

    return result.data;
  } catch (error) {
    console.error("Error", error.message);
    // Handle the error here
  }
}

/*=====================*/
/*  API for Payment Generation */
/*=====================*/
export async function generatePaymentUrl({
  wallet_addr,
  user,
  launchpad_id,
  collection_name,
  collection_addr,
  collection_desc,
  payment_amount,
  number_of_nfts,
  referral,
  provider,
}: {
  wallet_addr: string;
  user: string;
  launchpad_id: number;
  collection_name: string;
  collection_addr: string;
  collection_desc: string;
  payment_amount: number;
  number_of_nfts: number;
  referral: string,
  provider: string,
}) {
  const result = await axios.post(
    `${process.env.REACT_APP_MIDDLEWARE}payment/generate_paymentid`,
    {
      wallet_addr: wallet_addr,
      user: user,
      launchpad_id: launchpad_id,
      collection_name: collection_name,
      collection_addr: collection_addr,
      collection_desc: collection_desc,
      payment_amount: payment_amount,
      number_of_nfts: number_of_nfts,
      referral: referral,
      provider: provider,
    }
  );
  const url = result.data?.data?.url;
  return url;
}
