import { useEffect, useState } from "react";
import SwiperCore, {
  Navigation,
  Scrollbar,
  Pagination,
  Autoplay,
} from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/pagination/pagination.min.css";
import CountdownComp from "./countdown";

import styles from "./index.module.scss";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import {
  CurrencyRateType,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import { Trans } from "react-i18next";

const UpcomingForm = ({
  queryResult,
  launchpadNFT,
  launchpad_imageData,
  currencyRate,
  index,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT: LaunchPadNFT;
  launchpad_imageData?: any;
  currencyRate: CurrencyRateType;
  index?: Number;
}) => {
  SwiperCore.use([Navigation, Scrollbar, Pagination, Autoplay]);
  const { address } = useHook();
  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo.mintPrice?.split(" ") || ["", "USDC"];

  return (
    <>
      <div className={styles.project_social}>
        {/* <img src={notify} alt='thumb' /> */}
        {/* <Button
          className={styles.fav_btn}
          loading={isFavsLoading}
          disabled={!address}
          onClick={() => handleFav()}
        >
          <img src={isfav ? favA : fav} alt="thumb" />
        </Button> */}
      </div>

      <div className={styles.nft_view}>
        <div className={styles.header}>
          <h3>
            {launchpadNFT?.project_name === "Vibes" ||
            launchpadNFT?.project_name === "Vibes Demo"
              ? `${launchpad_imageData.title}`
              : launchpadNFT?.launchpad_type[Number(index)].fan_collection.project_name}
          </h3>
          <div className={styles.artist}>
            <div className={styles.profile}>
              <img
                src={
                  "https://loop-markets.directus.app/assets/" +
                  launchpadNFT?.artist.avatar.id
                }
                alt="thumb"
              />
              <div>
                <span>
                  <>CREATOR</>
                </span>
                <p>{launchpadNFT?.artist.first_name}</p>
              </div>
            </div>
            <div className={styles.divider} />
            <div>
              <span>
                <>Collection Type</>
              </span>
              <p style={{textTransform: "capitalize" }}>
                <>{launchpadNFT?.collection_type}</>
              </p>
            </div>
          </div>

          {/* NFT Mint Section */}
          <div className={styles.mint_live}>
            <div>
              <p className={styles.price_text}>
                <Trans>price</Trans>
              </p>
              <p className={styles.price_value}>
                {price == 0
                  ? `Free`
                  : `$${Number(price).toLocaleString()} ${symbol}`}
              </p>
              <p className={styles.convert} hidden={price == 0}>
                $
                {currencyRate
                  ? (currencyRate?.value * (price * 1)).toFixed(2)
                  : "..."}
                <span> {currencyRate && currencyRate?.code}</span>
              </p>
            </div>

            <button className={styles.mint_countdown}>
              {launchpadNFT.launch_tba ? (
                <span className={styles.soon}>
                  <>Coming Soon</>
                </span>
              ) : (
                <span>
                  <CountdownComp
                    launchpadNFT={launchpadNFT}
                    index={Number(index)}
                  />
                </span>
              )}
            </button>
          </div>
        </div>

        {/* Warn user that NFT will be random */}
        {launchpadNFT?.launchpad_type[Number(index)]?.type_gallery.length >
          1 && (
          <p style={{ textAlign: "center", color: "#677599" }}>
            <Trans>mintNote</Trans>
          </p>
        )}
      </div>
    </>
  );
};

export default UpcomingForm;
