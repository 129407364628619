import { useHook } from "app/hooks/common";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";

const NFTDesc = ({ info }: { info?: any; }) => {
  return (
    <div className={styles.desc}>
      <h6>
        <Trans>about</Trans>
      </h6>
      <p
        className={styles.about}
        dangerouslySetInnerHTML={{
          __html: info.metadata.description,
        }}
      />
      <hr />
    </div>
  );
};

export default NFTDesc;
