
import { useEffect, useState } from "react";
import { Card, Grid } from "semantic-ui-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import styles from "./club.module.scss";
import axios from "axios";
import { truncate } from "app/helpers/helper";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default function ClubView() {
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MIDDLEWARE}fans/creators`
        );
        const data = await response.data;
        setCreators(data);
        setLoading(true);
      } catch (err) {
        // Handle any errors
        setLoading(true);
      }
    }
    if (!loading) {
      fetchData();
    }
  }, [!creators, loading]);

  return (
    <div className={styles.clubs}>
      <div className={styles.container}>
        <Grid columns="one" divided>
          <Grid.Row className={styles.row}>
            <Grid.Column className={styles.column}>
              <h3>VIP Clubs</h3>
              <p className={styles.subHeading}><Trans>fanClubHeading</Trans></p>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {creators.length > 0 ? (
          <div>
            <div className={styles.club}>
              {creators?.map((creator, index) => {
                return (
                  <Link
                    to={`/${creator?.id}/posts`}
                    className={styles.item}
                    key={index}
                  >
                    <LazyLoadImage
                      alt={`${creator.id}`}
                      effect="blur"
                      src={
                        process.env.REACT_APP_DIRECTUS_BACKEND_ASSETS +
                        creator?.avatar?.id
                      }
                    />
                    <div className={styles.overlay} />
                    <Card.Content className={styles.content}>
                      {creator?.creator_type != "None" && (
                        <div className={styles.creator_type}>
                          <span>{creator?.creator_type}</span>
                        </div>
                      )}
                      <Card.Header
                        className={creator.disabled && styles.disabled}
                      >
                        <div className={styles.club_details}>
                          <p className={styles.details}>
                            {creator?.first_name}
                            <p className={styles.content}>
                              {truncate(creator?.description, 100)}
                            </p>
                            <br />
                          </p>
                        </div>
                      </Card.Header>
                    </Card.Content>
                  </Link>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="loader" />
        )}
      </div>
    </div>
  );
}
