import { useHook } from "app/hooks/common";
import styles from "./index.module.scss";
import { Trans } from "react-i18next";

const ProjectDesc = ({ info, index }: { info?: any; index: number }) => {
  return (
    <div className={styles.desc}>
          <h6><Trans>about</Trans> {info?.launchpad_type[Number(index)].collections_type?.display_name || info?.launchpad_type[Number(index)].fan_collection.name}</h6>
          <p
            className={styles.about}
            dangerouslySetInnerHTML={{
              __html:
                info?.launchpad_type[Number(index)].fan_collection.description,
            }}
          />
      <hr />
    </div>
  );
};

export default ProjectDesc;
