import { useContext, useEffect, useRef, useState } from "react";
import styles from "./gallery.module.scss";

import { Link, useHistory, useLocation } from "react-router-dom";

import { DirectusImage, UserAlbums } from "app/core/common";
import * as api from "app/api/profile.api";
import Loader from "app/components/Loader";
import Button from "app/components/button/button";
import { truncate } from "app/helpers/helper";
import { set } from "numeral";
import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import Modal from "app/components/Modal";
import NoContent from "app/components/NoContent";
import { t } from "i18next";

export default function ProfileGallery({ isFollowing, creator }) {
  const { cookie, userInfo } = useHook();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const profile_id = location.pathname.split("/")[1];

  const [albums, setAlbums] = useState<UserAlbums[]>([]);
  const [viewAlbum, setViewAlbum] = useState<Number>(null);
  const [transitionIn, setTransitionIn] = useState(false);

  // check gated access
  const [gatedAccessModal, setGatedAccessModal] = useState(false);
  // set image for modal
  const [modalImage, setModalImage] = useState(null);

  // handle modal
  const [showModal, setShowModal] = useState(false);
  const handleModal = (image) => {
    setModalImage(image);
    setShowModal(!showModal);
  };

  useEffect(() => {
    api.GetProfileGallery({ id: profile_id, cookie, userInfo }).then((data) => {
      setAlbums(data);
      setLoading(false);
    });
  }, [userInfo]);

  const handleAlbum = (id: number) => {
    setTransitionIn(!transitionIn);

    setTimeout(() => {
      id === viewAlbum ? setViewAlbum(null) : setViewAlbum(id);
    }, 500);
  };

  return (
    <>
      <div className={styles.gallery}>
        {loading ? (
          <Loader />
        ) : viewAlbum ? (
          albums
            ?.filter((item) => item.id === viewAlbum)
            ?.map((item, index) => {
              return (
                <div
                  hidden={
                    (profile_id !== userInfo?.id &&
                      item.access_type === "gated" &&
                      !item.access) ||
                    (profile_id !== userInfo?.id &&
                      item.access_type === "following" &&
                      !isFollowing)
                  }
                  key={item.name + index}
                  style={
                    !transitionIn
                      ? {
                          transition: "all 250ms ease-in-out",
                          opacity: 0,
                          transform: "translateX(-100%)",
                        }
                      : {
                          transition: "all 250ms ease-in-out",
                          opacity: 1,
                          transform: "translateX(0%)",
                        }
                  }
                >
                  <div className={styles.heading}>
                    <div className={styles.content}>
                      <div>
                        <div
                          className={styles.back_button}
                          onClick={() => handleAlbum(item.id)}
                        >
                          {creator?.id === "5c1f2716-ce35-48dd-a0de-a884f502592a" ? <>&#8249; Back to Offers</> : <>&#8249; <Trans>backToAlbums</Trans></>}
                        </div>
                        <h1 className={styles.title}>
                          <img
                            hidden={
                              item.access_type === "public" ||
                              item.access_type === "following"
                            }
                            src="/assets/icons/unlocked.svg"
                          />{" "}
                          &#8250; {item.name}
                        </h1>
                      </div>
                      <p className={styles.desc}>{item.desc}</p>
                    </div>
                  </div>
                  <div className={styles.images}>
                    {item?.gallery?.images?.map((item, index) => {
                      return (
                        <div
                          className={styles.card}
                          key={item.directus_files_id.id + index}
                        >
                          <div
                            className={styles.image}
                            style={{
                              backgroundImage: `url(${DirectusImage(
                                item.directus_files_id.id
                              )})`,
                            }}
                            onClick={() =>
                              handleModal(
                                DirectusImage(item.directus_files_id.id)
                              )
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  <hr />
                </div>
              );
            })
        ) : albums.length > 0 ? (
          albums?.map((item, index) => {
            return (
              <div
                key={item.name + index}
                style={
                  transitionIn
                    ? {
                        transition: "all 250ms ease-in-out",
                        opacity: 0,
                        transform: "translateX(100%)",
                      }
                    : {
                        transition: "all 250ms ease-in-out",
                        opacity: 1,
                        transform: "translateX(0%)",
                      }
                }
              >
                <div className={styles.heading}>
                  <div className={styles.content}>
                    <h1 className={styles.title}>{item.name}</h1>
                    <p className={styles.desc}>
                      {item.desc && truncate(item.desc, 150, "...")}
                    </p>
                  </div>
                  <div>
                    <Button
                      className={"button_locked"}
                      onClick={() =>
                        profile_id === userInfo?.id ||
                        (item.access_type === "gated" && item.access) ||
                        (item.access_type === "following" && isFollowing) ||
                        item.access_type === "public"
                          ? handleAlbum(item.id)
                          : setGatedAccessModal(true)
                      }
                    >
                      {(profile_id !== userInfo?.id &&
                        item.access_type === "gated" &&
                        !item.access) ||
                      (item.access_type === "following" && !isFollowing) ? (
                        <>
                          <img src="/assets/icons/locked.svg" />
                          Get Access &#8250;
                        </>
                      ) : (
                        <>
                          <img
                            hidden={
                              item.access_type === "public" ||
                              item.access_type === "following"
                            }
                            src="/assets/icons/unlocked.svg"
                          />
                          {creator?.id === "5c1f2716-ce35-48dd-a0de-a884f502592a" ? <>View Offers &#8250;</> : <>View Album &#8250;</> }
                        </>
                      )}
                    </Button>
                  </div>
                </div>

                <div className={styles.images}>
                  {item?.gallery?.images
                    .filter((item) => item.featured === true)
                    .slice(0, 3) // Limit the number of items to 3
                    .map((item, index) => {
                      return (
                        <div
                          className={styles.card}
                          key={item.directus_files_id.id + index}
                        >
                          <div
                            className={styles.image}
                            style={{
                              backgroundImage: `url(${DirectusImage(
                                item.directus_files_id.id
                              )})`,
                            }}
                            onClick={() =>
                              handleModal(
                                DirectusImage(item.directus_files_id.id)
                              )
                            }
                          />
                        </div>
                      );
                    })}
                </div>

                <hr />
              </div>
            );
          })
        ) : (
          <NoContent message={t("noGalleryMessage", {user: creator?.first_name})} showButton={false} />
        )}
      </div>

      {/* Gated Access Modal */}
      <Modal
        isOpen={gatedAccessModal}
        onClose={() => setGatedAccessModal(false)}
        title={"Content Locked"}
      >
        <div
          style={{
            textAlign: "center",
            marginBottom: "50px",
            padding: "10px 30px",
          }}
        >
          <p>
            <Trans>gatedContentClubOnGoing</Trans>
          </p>
          <button
            className="button"
            onClick={() => history.push(`/${profile_id}/collections`)}
          >
            <Trans>browseCollecitons</Trans>
          </button>
        </div>
      </Modal>

      {/* Gallery Modal */}
      <Modal isOpen={showModal} onClose={() => handleModal(null)} title={""}>
        <img
          src={modalImage}
          style={{ width: "100%", marginTop: "-40px", borderRadius: "15px" }}
        />
      </Modal>
    </>
  );
}
